import React from 'react';
import { useHistory,Link } from 'react-router-dom';
import * as AuthService from '../service/AuthService';
import ENDPOINT from '../../config/endpoint';
import {BASE_URI,PREFIX_URI} from '../../config/config';
import {apiCall} from '../../utils';

const UserProfileComponent = () => {
    const USERDATA = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).userData : ''
    const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken:''
    const history = useHistory();
    const handleLogout = async(event) => {
        event.preventDefault();
        AuthService.logout();
        history.push('/login')
        const url = BASE_URI+PREFIX_URI+ENDPOINT.LOGOUT;
        const logoutApiCall = await apiCall(TOKEN,url,'DELETE','');
        const responses = await logoutApiCall;
    }
    return(
        <div id="kt_quick_user" className="offcanvas offcanvas-right p-10">
            {/*begin::Header*/}
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 className="font-weight-bold m-0">User Profile
                <small className="text-muted font-size-sm ml-2">12 messagessss</small></h3>
                <Link to="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                    <i className="ki ki-close icon-xs text-muted" />
                </Link>
            </div>
            {/*end::Header*/}
            {/*begin::Content*/}
            <div className="offcanvas-content pr-5 mr-n5">
                {/*begin::Header*/}
                <div className="d-flex align-items-center mt-5">
                    <div className="symbol symbol-100 mr-5">
                        <div className="symbol-label" style={{backgroundImage: `url("${USERDATA.profile_picture}")`}} />
                        <i className="symbol-badge bg-success" />
                    </div>
                    <div className="d-flex flex-column">
                        <Link to="#" className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">{USERDATA.name}</Link>
                        <div className="text-muted mt-1">Application Developer</div>
                        <div className="navi mt-2">
                            <Link to="#" className="navi-item">
                                <span className="navi-link p-0 pb-2">
                                    <span className="navi-icon mr-1">
                                        <span className="svg-icon svg-icon-lg svg-icon-primary">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Communication/Mail-notification.svg*/}
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <rect x={0} y={0} width={24} height={24} />
                                                <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                </g>
                                            </svg>
                                        {/*end::Svg Icon*/}
                                        </span>
                                    </span>
                                    <span className="navi-text text-muted text-hover-primary">{USERDATA.email}</span>
                                </span>
                            </Link>
                            <Link to="#" onClick={(e)=> {handleLogout(e)}} className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">Sign Out</Link>
                        </div>
                    </div>
                </div>
                {/*end::Header*/}
                {/*begin::Separator*/}
                {/* <div className="separator separator-dashed mt-8 mb-5" /> */}
                {/*end::Separator*/}
                {/*begin::Separator*/}
                <div className="separator separator-dashed my-7" />
                {/*end::Separator*/}
                {/*begin::Notifications*/}
                <div>
                    {/*begin:Heading*/}
                    <h5 className="mb-5">Recent Notifications</h5>
                    {/*end:Heading*/}
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">
                        <span className="svg-icon svg-icon-success mr-5">
                            <span className="svg-icon svg-icon-lg">
                                {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Communication/Write.svg*/}
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)" />
                                        <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                    </g>
                                </svg>
                                {/*end::Svg Icon*/}
                            </span>
                        </span>
                        <div className="d-flex flex-column flex-grow-1 mr-2">
                            <Link to="/ticket/list" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Ticket Belum Close</Link>
                        </div>
                        <span className="font-weight-bolder text-success py-1 font-size-lg">50 Ticket</span>
                    </div>
                    {/*end::Item*/}
                </div>
                {/*end::Notifications*/}
            </div>
            {/*end::Content*/}
        </div>

    )
}

export default UserProfileComponent