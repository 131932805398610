import React,{ useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Form, Container, Col, Dropdown, Row } from "react-bootstrap";
import {BASE_URI,PREFIX_URI,ENDPOINT,Notification} from '../../../config';
import {apiCall, buildValidationError,converDateTime} from '../../../utils';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { data, type } from 'jquery';
import { useUsers } from '../../context/UsersProvider';

const $ = require('jquery')
$.Datatable = require('datatables.net')

const UserTableFunction = ({ tableRef }) => {
    const USERNAME = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).userData.name:''
    const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken:''
    const [modal,setModal] = useState({
        isShowModalUpdate:false,
        isShowModalMenu:false,
        isShowModalResponse:false,
        data: null,
        dataMenu:[]
    });

    const [textInput, setTextInput] = useState({
        textInputNama:'',
        textInputEmail:'',
        textInputNoTlp:'',
        textInputAlamat:'',
        textInputPassword:'',
        textInputConfirmPassword:'',
        textInputIsAdmin:null
    });

    const { updateAsignMenu } = useUsers()

    const tableElement = tableRef;

    useEffect(()=>{
        $(tableElement.current).DataTable(
            {
                ajax: {
                    url : BASE_URI+PREFIX_URI+ENDPOINT.USER_LIST_USER,
                    method:'POST',
                    data:{
                        table:'m_users',
                        parameter:[{
                            key:'deleted_by',
                            value:'NULL'
                        }],
                    }
                },
                destroy:true,
                serverSide:true,
                columns: [
                    { "name": "name" },
                    { "name": "email" },
                    { "name": "phone_number" },
                    { "name": "address" },
                    // { "name": "lastlogin" },
                    {
                        "name": "lastlogin", render: (data, type, full, meta) => {
                        return converDateTime(data)
                    } },
                    { "name": "is_active",render:(data,type,full,meta) => {
                        let status = {
                            1: {'title': 'Active', 'class': ' label-light-success'},
                            0: {'title': 'Not Active', 'class': ' label-light-danger'},
                        }
                        if (typeof status[data] === 'undefined') {
                            return data;
                        }
                        return '<span class="label label-lg font-weight-bold' + status[data].class + ' label-inline">' + status[data].title + '</span>';
                    }},
                    { "name": "is_admin",render:(data,type,full,meta) => {
                        let status = {
                            1: {'title': 'Admin', 'class': ' label-light-success'},
                            0: {'title': 'Member', 'class': ' label-light-primary'},
                        }
                        if (typeof status[data] === 'undefined') {
                            return data;
                        }
                        return '<span class="label label-lg font-weight-bold' + status[data].class + ' label-inline">' + status[data].title + '</span>';
                    }},
                    {"name": "id"}
                ],
                columnDefs:[
                    {
                        targets:[7],
                        className:"center",
                        createdCell:(td,cellData,rowData)=> {
                            ReactDOM.render(
                                <div>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <i className="flaticon-edit"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleUpdateButton(rowData[7]) } >Update</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleDeleteButton(rowData[7],rowData[1]) } >Delete</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleAsignMenu(rowData[7]) } >Asign Menu</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>,td
                            );
                        }
                    }
                ]
            }
        )
    }, [])

    const handleUpdateButton = async (id_user) => {
      try {
        //request new Token
        const urlToken = BASE_URI + PREFIX_URI + ENDPOINT.TOKEN;
        let requestToken = await apiCall(
          TOKEN,
          urlToken,
          "POST",
          JSON.stringify({ token: TOKEN })
        );

        const url = BASE_URI + PREFIX_URI + ENDPOINT.USER_GET_USER + id_user;
        console.log("url", url);
        // const rawBody = JSON.stringify({id_user:id_user});
        let responses = await apiCall(requestToken.accessToken, url, "GET", {});
        if (!responses.success) {
          if (responses.message.errors) {
            let errorString = buildValidationError(responses.message.errors);
            Notification.failed("Failed", errorString, "top-right");
            return;
          }
          return Notification.failed("Failed", responses.message, "top-right");
        } else {
          setModal({ ...modal, isShowModalUpdate: true, data: id_user });
          if (responses.data != null || responses.data.length != 0) {
            setTextInput({
              ...textInput,
              textInputEmail: responses.data[0].email,
              textInputNama: responses.data[0].name,
              textInputNoTlp: responses.data[0].phone_number,
              textInputAlamat: responses.data[0].address,
              textInputIsAdmin:
                responses.data[0].is_admin == "1" ? true : false,
            });
            return;
          }
          return Notification.failed("Failed", "Data Not Found!", "top-right");
        }
      } catch (e) {
        console.log(e);
      }
    };

    const handleDeleteButton = (id, email) => {
      Swal.fire({
        title: `User ${email} Yakin ingin dihapus?`,
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            //request new Token
            const urlToken = BASE_URI + PREFIX_URI + ENDPOINT.TOKEN;
            let apiCallRequestToken = apiCall(
              TOKEN,
              urlToken,
              "POST",
              JSON.stringify({ token: TOKEN })
            );
            let requestToken = await apiCallRequestToken;

            const url = BASE_URI + PREFIX_URI + ENDPOINT.USER_DELETE_USER + id;
            let responses = await apiCall(
              requestToken.accessToken,
              url,
              "DELETE",
              JSON.stringify({ action_by: USERNAME })
            );

            if (!responses.success) {
              if (responses.message.errors !== null)
                return Notification.failed(
                  "Failed",
                  buildValidationError(responses.message.errors),
                  "top-right"
                );
              return Notification.failed(
                "Failed",
                responses.message,
                "top-right"
              );
            }
            $(tableElement.current).DataTable().ajax.reload();
            return Notification.success(
              "Success",
              responses.message,
              "top-right"
            );
          } catch (e) {
            console.log(e);
            Notification.failed("Failed", e.toString(), "top-right");
          }
        }
      });
    };
    
    const handleAsignMenu = async (id_user) => {
      try {
        //request new Token
        const urlToken = BASE_URI + PREFIX_URI + ENDPOINT.TOKEN;
        let requestToken = await apiCall(
          TOKEN,
          urlToken,
          "POST",
          JSON.stringify({ token: TOKEN })
        );

        const url = BASE_URI + PREFIX_URI + ENDPOINT.USER_LIST_MENU;
        let responses = await apiCall(
          requestToken.accessToken,
          url,
          "POST",
          JSON.stringify({ id_user: id_user })
        );
        if (!responses.success) {
          if (responses.message.errors) {
            let errorString = buildValidationError(responses.message.errors);
            Notification.failed("Failed", errorString, "top-right");
            return;
          }
          return Notification.failed("Failed", responses.message, "top-right");
        } else {
          setModal({
            ...modal,
            isShowModalMenu: true,
            dataMenu: responses.data,
            data: id_user,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    const handleCloseModal = () => {
        setModal({
            ...modal,
            data:null,
            dataMenu:[],
            isShowModalUpdate:false,
            isShowModalMenu:false,
            isShowModalResponse:false
        })
    }

    const handleSubmitUpdate = async(e) => {
        e.preventDefault();
        try{
            //request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let requestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
    
            // const url = `${BASE_URI+PREFIX_URI+ENDPOINT.USER_UPDATE_USER} + ${modal.data}`;
            const url = BASE_URI+PREFIX_URI+ENDPOINT.USER_UPDATE_USER + modal.data;
            const rawBody = JSON.stringify({
                email:textInput.textInputEmail,
                name:textInput.textInputNama,
                address:textInput.textInputAlamat,
                password:textInput.textInputPassword,
                password_confirmation:textInput.textInputConfirmPassword,
                phone:textInput.textInputNoTlp,
                is_admin:textInput.textInputIsAdmin ? 1 : 0,
                action_by:USERNAME,
            });
            let responses = await apiCall(requestToken.accessToken,url,'PUT',rawBody);
            if(!responses.success){
                if(responses.message.errors !== null) return Notification.failed('Failed',buildValidationError(responses.message.errors),'top-right');
                return Notification.failed('Failed',responses.message,'top-right');
            }
            handleCloseModal();
            $(tableElement.current).DataTable().ajax.reload();
            return Notification.success('Success',responses.message,'top-right');
        }catch(e){
            console.log(e)
            return Notification.failed('Failed',e,'top-right');
        }
    }

    const handleSubmitAsignMenu = () => {
        const formData = {
            url: BASE_URI+PREFIX_URI+ENDPOINT.USER_UPDATE_MENU,
            payload: JSON.stringify({id_user:modal.data,asignMenu:modal.dataMenu}),
            method: 'PUT',
        }
        updateAsignMenu(formData);
        handleCloseModal();
    }

    return(
        <div>
            <table className="table table-bordered table-hover dataTable" id="dataTable" style={{marginTop: '13px !important'}} ref={tableRef}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>email</th>
                        <th>No Tlp</th>
                        <th>Alamat</th>
                        <th>Last Login</th>
                        <th>Status</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
            </table>
            <Modal 
                show={modal.isShowModalUpdate} 
                centered 
                onHide={handleCloseModal}
                size='lg'
                // dialogClassName='modal-90w'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Data User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form onSubmit={handleSubmitUpdate}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Nama</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter Nama"
                                    onChange={e => setTextInput({...textInput,textInputNama:e.target.value})}
                                    value={textInput.textInputNama}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Enter email" 
                                    onChange={e => setTextInput({...textInput,textInputEmail:e.target.value})}
                                    value={textInput.textInputEmail}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicTlp">
                                <Form.Label>No Tlp</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter No Tlp" 
                                    onChange={e => setTextInput({...textInput,textInputNoTlp:e.target.value})}
                                    value={textInput.textInputNoTlp}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicAlamat">
                                <Form.Label>Alamat</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Alamat" 
                                    onChange={e => setTextInput({...textInput,textInputAlamat:e.target.value})}
                                    value={textInput.textInputAlamat}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Password" 
                                    onChange={e => setTextInput({...textInput,textInputPassword:e.target.value})}
                                    value={textInput.textInputPassword}
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Ulangi Password" 
                                    onChange={e => setTextInput({...textInput,textInputConfirmPassword:e.target.value})}
                                    value={textInput.textInputConfirmPassword}
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check 
                                    type="checkbox" 
                                    label="Set Admin"
                                    defaultChecked={textInput.textInputIsAdmin}
                                    checked={textInput.textInputIsAdmin}
                                    value={textInput.textInputIsAdmin}
                                    onChange={(e) => setTextInput({...textInput,textInputIsAdmin:e.target.checked})} 
                                />
                            </Form.Group>
                            <Col>
                                <Row>
                                    <Button variant="primary" type="submit" >Submit</Button>
                                    <Button variant="secondary" className="float-right" type="button" onClick={handleCloseModal}>Close</Button>
                                </Row>
                            </Col>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal
                show={modal.isShowModalMenu} 
                centered 
                onHide={handleCloseModal}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Asign User Menu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <div className="row mb-3" style={{ borderBottom:'1px solid' }}>
                            <div className="col-md-4"><h3>Menu</h3></div>
                            <div className="col-md-8"><h3>Sub Menu</h3></div>
                            <hr />
                        </div>
                        {
                            modal.dataMenu.length > 0 ? 
                            modal.dataMenu.map((menu, index) => {
                                return (
                                    <>
                                        <div className="row mb-3" style={{ borderBottom: '1px solid rgb(235, 237, 243)' }} key={index}>
                                            <div className="col-md-4">
                                                <div className="row">
                                                    <div className="col-md-1 mr-4">
                                                        <input
                                                            name="isGoing"
                                                            type="checkbox"
                                                            checked={menu.menuAsign}
                                                            onChange={(e) => {
                                                                const dataAwal = modal.dataMenu;
                                                                dataAwal[index].menuAsign = e.target.checked;
                                                                dataAwal[index].submenuList.map((value, index) => {
                                                                    value.asigned = e.target.checked
                                                                })
                                                                setModal({...modal,dataMenu:dataAwal})
                                                            }}
                                                        />
                                                    </div>
                                                    {menu.menuName}
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                {
                                                    menu.submenuList.map((submenu, secondIndex) => {
                                                        return (
                                                            <div className="row" key={secondIndex}>
                                                                <div className="col-md-1">
                                                                    <input
                                                                        name="isGoing"
                                                                        type="checkbox"
                                                                        checked={submenu.asigned}
                                                                        onChange={(e) => {
                                                                            const dataAwal = modal.dataMenu;
                                                                            dataAwal[index].submenuList[secondIndex].asigned = e.target.checked
                                                                            setModal({...modal,dataMenu:dataAwal})
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <label>
                                                                    {submenu.name}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            : <></>
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    <Button variant="primary" onClick={handleSubmitAsignMenu}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UserTableFunction;