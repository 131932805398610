import React, { useRef, useState, useEffect } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { BASE_URI, PREFIX_URI, ENDPOINT } from '../../../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useItems } from '../../context/ItemsProvider';


const ListItemComponent = () => {
    const USERDATA = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).userData : ''
    const [data,setData] = useState({
        isBlocking:false,
        isValid:false,
        formResult:false,
        formModal:false,
        itemIdForModal:'',
        itemDescriptionForModal:'',
        itemHscodeForModal:'',
        itemPiecesForModal:'',
        itemValueForModal:'',
        itemCurrencyForModal:''
    })

    const { listItems, getListItems, updateDataItem, deleteDataItem } = useItems()
    const [textServiceNumber,setTextServiceNumber] = useState(null);
    const [resultApi, setResultApi] = useState(null);
    const inputTextServiceNumber = useRef();
    const labelHelpText = useRef();

    const handleSubmit = () => {
        if(!data.isValid){
            return;
        }
        setData({ ...data, isBlocking: true })
        const formData = {
            url: BASE_URI + PREFIX_URI + ENDPOINT.CAS_GET_LIST_ITEM_SERVICE_NUMBER + textServiceNumber,
            payload: JSON.stringify({ service_number: textServiceNumber }),
            method: 'GET'
        }
        getListItems(formData)
    }

    const renderButton = (data_item) => {
        return(
            <div>
                <button className="btn" title="Update" type="button" onClick={() => showFormInModal(data_item)}><i className="flaticon2-pen text-warning"></i></button>
                <button className="btn" title="Delete" type="button" onClick={() => handleDeleteButton(data_item)}><i className="flaticon2-cross text-danger"></i></button>
            </div>
        )
    }

    const handleUpdateButton = async () => {
        data.data_before = listItems;
        const formData = {
            url: BASE_URI + PREFIX_URI + ENDPOINT.CAS_GET_LIST_ITEM_SERVICE_NUMBER + textServiceNumber,
            method: 'PUT',
            payload: JSON.stringify({
                id_item:data.itemIdForModal,
                description:data.itemDescriptionForModal,
                hscode:data.itemHscodeForModal,
                product_value:data.itemValueForModal,
                product_pieces_xml:data.itemPiecesForModal,
                currancy: data.itemCurrencyForModal,
                created_by: USERDATA.name,
                data_before: data.data_before
            }),
        }

        updateDataItem(formData);
        setData({...data,formModal:false})
    }

    const showFormInModal = (data_item) => {
        setData({
            ...data,
            itemDescriptionForModal:data_item.product_description,
            itemHscodeForModal:data_item.hscode,
            itemPiecesForModal:data_item.product_pieces_xml,
            itemCurrencyForModal:data_item.currancy,
            itemValueForModal:data_item.product_value,
            itemIdForModal:data_item.id, 
            formModal:true
        })
    }

    const handleDeleteButton = (data_item) => {
        Swal.fire({
            title: 'Deskripsi [ ' +data_item.product_description + ' ] Yakin ingin dihapus?',
            text: "Data tidak bisa dikembalikan!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Hapus!',
            cancelButtonText:'Batal'
        }).then(async(result) => {
            if (result.isConfirmed) {
                const formData = {
                    url: BASE_URI + PREFIX_URI + ENDPOINT.CAS_DELETE_ITEM_BY_ID + textServiceNumber,
                    method: 'DELETE',
                    payload: JSON.stringify({id_item:data_item.id,data_before:listItems,created_by: USERDATA.name})
                }
                deleteDataItem(formData);
            }
        })
    }

    useEffect(() => {
        if (listItems.length > 0) {
            setResultApi(listItems)
        }
        setData({ ...data, isBlocking: false })
    }, [listItems])

    return(
        <div className="container">
            <div className="row">
                <div className="col-md">
                    {/* <BlockUi tag="div" blocking={isBlocking} renderChildren={false}> */}
                        {/*begin::Card*/}
                        <div className="card card-custom gutter-b example example-compact">
                            <div className="card-header">
                                <h3 className="card-title">Review Item Service Number</h3>
                            </div>
                            {/*begin::Form*/}
                            <form onSubmit={(event)=>{event.preventDefault();handleSubmit()}}>
                                <div className="card-body">
                                    <BlockUi tag="div" blocking={data.isBlocking} renderChildren={false}>
                                        <div className="form-group mb-8">
                                            <div className="alert alert-custom alert-default" role="alert">
                                                <div className="alert-icon">
                                                    <span className="svg-icon svg-icon-primary svg-icon-xl">
                                                        {/*begin::Svg Icon | path:assets/media/svg/icons/Tools/Compass.svg*/}
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                <rect x={0} y={0} width={24} height={24} />
                                                                <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3" />
                                                                <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero" />
                                                            </g>
                                                        </svg>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </div>
                                                <div className="alert-text">Untuk melihat list item, bisa menghapus item yang salah input, bisa update description item</div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Service Number
                                                <span className="text-danger"> * </span></label>
                                            <input 
                                                type="text" 
                                                ref={inputTextServiceNumber} 
                                                className="form-control"
                                                value={textServiceNumber}
                                                required 
                                                placeholder="Service Number" 
                                                onChange={
                                                    (e)=> {
                                                        setTextServiceNumber(e.target.value);
                                                        // setServiceNumber(e.target.value);
                                                        if(e.target.value.length < 11){
                                                            inputTextServiceNumber.current.classList.add('is-invalid');
                                                            labelHelpText.current.classList.remove('text-muted');
                                                            labelHelpText.current.classList.add('text-danger');
                                                            labelHelpText.current.innerHTML = 'Service Number tidak valid';
                                                        }else{
                                                            inputTextServiceNumber.current.classList.remove('is-invalid');
                                                            inputTextServiceNumber.current.classList.add('is-valid');
                                                            labelHelpText.current.classList.remove('text-muted');
                                                            labelHelpText.current.classList.remove('text-danger');
                                                            labelHelpText.current.classList.add('text-success');
                                                            labelHelpText.current.innerHTML = 'Service Number valid';
                                                            setData({...data,isValid:true})
                                                        }
                                                    }
                                                }/>
                                            <span className="form-text text-muted" ref={labelHelpText}>Harap isi dengan Service Number</span>
                                        </div>
                                    </BlockUi>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary mr-2">Submit</button>
                                    {/* <button type="reset" className="btn btn-secondary">Cancel</button> */}
                                </div>
                            </form>
                            {/*end::Form*/}
                        </div>
                        {/*end::Card*/}
                        {/*begin::Card*/}
                        { resultApi ?
                            <div className="card card-custom gutter-b">
                                <div className="card-header flex-wrap py-3">
                                    <div className="card-title">
                                    <h3 className="card-label">Daftar Item</h3>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {/*begin: Datatable*/}
                                    <table className="table table-bordered table-checkable" id="kt_datatable">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Id</th>
                                                <th>Deskripsi</th>
                                                <th>HSCODE</th>
                                                <th>Jumlah Pcs</th>
                                                <th>Value</th>
                                                <th>Satuan</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {resultApi.map((value,index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{value.id}</td>
                                                        <td>{value.product_description}</td>
                                                        <td>{value.hscode}</td>
                                                        <td>{value.product_pieces_xml}</td>
                                                        <td>{value.product_value}</td>
                                                        <td>{value.currancy}</td>
                                                        <td>{renderButton(value)}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {/*end: Datatable*/}
                                </div>
                            </div>
                            : null
                        }
                        {/*end::Card*/}
                    {/* </BlockUi> */}
                </div>
            </div>
            <Modal show={data.formModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Data Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                    <Form onSubmit={(e) => {e.preventDefault();}}>
                        {resultApi ? 
                            <div className="col-lg-12">

                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label>Description
                                                <span className="text-danger"> * </span>
                                            </label>
                                            <input 
                                                type="text"
                                                value={data.itemDescriptionForModal}
                                                // ref={inputTextServiceNumber} 
                                                className="form-control" 
                                                required 
                                                placeholder="Service Number"
                                                onChange={(e) => {e.preventDefault();setData({...data,itemDescriptionForModal:e.target.value})}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                        <label>HSCODE
                                            <span className="text-danger"> * </span>
                                        </label>
                                        <input 
                                            type="text"
                                            value={data.itemHscodeForModal}
                                            // ref={inputTextServiceNumber} 
                                            className="form-control" 
                                            required 
                                            placeholder="Jumlah Pieces"
                                            onChange={(e) => {e.preventDefault();setData({...data,itemHscodeForModal:e.target.value})}}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                        <label>Jumlah Pieces
                                            <span className="text-danger"> * </span>
                                        </label>
                                        <input 
                                            type="text"
                                            value={data.itemPiecesForModal}
                                            // ref={inputTextServiceNumber} 
                                            className="form-control" 
                                            required 
                                            placeholder="Jumlah Pieces"
                                            onChange={(e) => {e.preventDefault();setData({...data,itemPiecesForModal:e.target.value})}}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Value
                                                <span className="text-danger"> * </span>
                                            </label>
                                            <input 
                                                type="text"
                                                value={data.itemValueForModal}
                                                // ref={inputTextServiceNumber} 
                                                className="form-control" 
                                                required 
                                                placeholder="Value"
                                                onChange={(e) => {e.preventDefault();setData({...data,itemValueForModal:e.target.value})}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Currency
                                                <span className="text-danger"> * </span>
                                            </label>
                                            <input 
                                                type="text"
                                                value={data.itemCurrencyForModal}
                                                // ref={inputTextServiceNumber} 
                                                className="form-control" 
                                                required 
                                                placeholder="Currency"
                                                onChange={(e) => {e.preventDefault();setData({...data,itemCurrencyForModal:e.target.value})}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :null
                        }
                    </Form>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleUpdateButton()}>Simpan</Button>
                    <Button variant="secondary" onClick={() => setData({...data,formModal:false})}>Tutup</Button>
                    {/* <button className="btn" title="Delete" onClick={() => setData({...data,formModal:false})}><i className="flaticon2-cross text-danger"></i></button> */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ListItemComponent;