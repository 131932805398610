
import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    withRouter
} from "react-router-dom";

//redux
import { connect } from 'react-redux';
import {postLogin,postTicket} from '../../redux/ActionCreators';

import LoginComponent from '../auth/LoginComponent';
import DashboardComponent from "../pages/home/DashboardComponent";
import ReviewXmlComponent from "../pages/cas/ReviewXmlComponent";
import GetStatusBKComponent from "../pages/cas/GetStatusBKComponent";
import PushDataComponent from "../pages/cas/PushDataComponent";
import ReviewLogXmlComponent from "../pages/cas/LogXmlComponent";
import UnlockSendingComponent from "../pages/cas/UnlockSendingComponent";
import UserComponent from "../pages/master/UserComponent";
import ProtectedRoute from "./ProtecedRoute";
import ListItemComponent from "../pages/cas/ListItemComponent";
import RecalculateComponent from "../pages/gtln/RecalculateComponent";
import UnlockFlagImeiComponent from "../pages/gtln/UnlockFlagImeiComponent";
import DeleteManifestComponent from "../pages/gtln/DeleteManifestComponent";
import DataShipperComponent from "../pages/gtln/DataShipperComponent";
import ViewLogUserComponent from "../pages/gtln/ViewLogUserComponent";
import FormTicketComponent from "../pages/tiketing/FormTicketComponent";
import ListTicketComponent from "../pages/tiketing/ListTicketComponent";
import UpdateHeaderComponent from "../pages/cas/UpdateHeaderComponent";
import MenuComponent from "../pages/master/MenuComponent";

const mapStateToProps = state => {
    return {
        AuthState: state.AuthState
    }
}

const mapDispatchToProps = dispatch => ({
    mPostLogin: (email,password) => dispatch(postLogin(email,password)),
    mPostTicket: (email,password) => dispatch(postTicket(email,password)),
});

const MainRouter = (props) => {
    return (
        <Router>
                <Route path="/login" component={() => <LoginComponent postLogin={props.mPostLogin} />}/>
                <Switch>
                    <ProtectedRoute path="/" exact component={DashboardComponent} />
                    <ProtectedRoute path="/dashboard" component={DashboardComponent} />

                    {/* Route CAS */}
                    {/* <ProtectedRoute path="/cas/unlockSendingXml" component={UnlockSendingComponent} /> */}
                    <ProtectedRoute path="/cas/pushDataUpload" component={PushDataComponent} />
                    <ProtectedRoute path="/cas/downloadXml" component={ReviewXmlComponent} />
                    <ProtectedRoute path="/cas/logXml" component={ReviewLogXmlComponent} />
                    <ProtectedRoute path="/cas/getRespon" component={GetStatusBKComponent} />
                    <ProtectedRoute path="/cas/listItem" component={ListItemComponent} />
                    <ProtectedRoute path="/cas/updateDataHeader" component={UpdateHeaderComponent} />
                    {/* Route GTLN */}
                    <ProtectedRoute path="/gtln/recalculate" component={RecalculateComponent} />
                    <ProtectedRoute path="/gtln/unlockImei" component={UnlockFlagImeiComponent} />
                    <ProtectedRoute path="/gtln/deleteManifest" component={DeleteManifestComponent} />
                    <ProtectedRoute path="/gtln/logUser" component={ViewLogUserComponent} />
                    <ProtectedRoute path="/gtln/dataShipper" component={DataShipperComponent} /> 

                    {/* Route Ticketing */}
                    <ProtectedRoute path="/ticket/create" component={FormTicketComponent} action={props.mPostTicket}/>
                    <ProtectedRoute path="/ticket/list" component={ListTicketComponent} />
                    {/* Route MASTER */}
                    <ProtectedRoute path="/master/user" component={UserComponent} />
                    <ProtectedRoute path="/master/menu" component={MenuComponent} />
                </Switch>
        </Router>
    );
}

// export default MainRouter;
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MainRouter));