import React, { useRef, useState } from 'react';
import ENDPOINT from '../../../config/endpoint';
import {BASE_URI,PREFIX_URI} from '../../../config/config';
import Notification from '../../../config/notification';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {apiCall,buildValidationError, converDateTime} from '../../../utils';
var format = require('xml-formatter');



const ReviewXmlComponent = () => {
    const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken:''
    const [data,setData] = useState({
        isBlocking:false,
        isValid:false,
        formResult:false,
    })
    const [textServiceNumber,setTextServiceNumber] = useState(null);
    const [resultApi,setResultApi] = useState(null);
    // const [isBlocking,setIsBlocking] = useState(false);
    // const [isValid,setIsValid] = useState(false);
    const inputTextServiceNumber = useRef();
    const labelHelpText = useRef();
    const resultBox = useRef();
    const handleSubmit = async() => {
        if(!data.isValid){
            return;
        }
        try{
            // setIsBlocking(true)
            setData({...data,isBlocking:true})

            //request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let apiCallRequestToken = apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
            let requestToken = await apiCallRequestToken

            // api call
            const url = BASE_URI+PREFIX_URI+ENDPOINT.CAS_GET_XML;
            let unlockSendingXml = apiCall(requestToken.accessToken,url,'POST',JSON.stringify({'service_number':textServiceNumber}));;
            let responses = await unlockSendingXml;

            // const myHeaders = new Headers();
            // myHeaders.append("Content-Type", "application/json");
            
            // var requestOptions = {
            //     method: 'POST',
            //     headers: myHeaders,
            //     body: JSON.stringify({'service_number':textServiceNumber}),
            //     redirect: 'follow'
            // };

            // const url = BASE_URI+PREFIX_URI+ENDPOINT.CAS_GET_XML;
            // let loginApiCall = await fetch( url, requestOptions);
            // let responses = await loginApiCall.json();
            // setIsBlocking(false)
            setData({...data,isBlocking:false})
            // setIsValid(false);
            setData({...data,isValid:false})
            if(!responses.success){
                // error validasi
                setResultApi(null);
                if(responses.message.errors){
                    let errorString = buildValidationError(responses.message.errors);
                    Notification.failed('Failed',errorString,'top-right');
                    return;
                }
                Notification.failed('Failed',responses.message,'top-right');
            }else{
                Notification.success('Success',responses.message,'top-right');
                setTextServiceNumber(null);
                setResultApi(responses.data)
                inputTextServiceNumber.current.value="";
                inputTextServiceNumber.current.focus();
                inputTextServiceNumber.current.classList.remove('is-invalid');
                labelHelpText.current.classList.remove('text-danger');
                labelHelpText.current.classList.add('text-muted');
                labelHelpText.current.innerHTML = 'Harap isi dengan Service Number';
            }
        }catch(error){
            console.log(error);
            setData({...data,isBlocking:false})
            Notification.failed('Error','Please Contact Admin!','top-right');
        }
    }

    return(
        <div className="container">
            <div className="row">
                <div className="col-md">
                    {/* <BlockUi tag="div" blocking={isBlocking} renderChildren={false}> */}
                        {/*begin::Card*/}
                        <div className="card card-custom gutter-b example example-compact">
                            <div className="card-header">
                                <h3 className="card-title">Review XML</h3>
                            </div>
                            {/*begin::Form*/}
                            <form onSubmit={(event)=>{event.preventDefault();handleSubmit()}}>
                                <div className="card-body">
                                    <BlockUi tag="div" blocking={data.isBlocking} renderChildren={false}>
                                        <div className="form-group mb-8">
                                            <div className="alert alert-custom alert-default" role="alert">
                                                <div className="alert-icon">
                                                    <span className="svg-icon svg-icon-primary svg-icon-xl">
                                                        {/*begin::Svg Icon | path:assets/media/svg/icons/Tools/Compass.svg*/}
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                <rect x={0} y={0} width={24} height={24} />
                                                                <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3" />
                                                                <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero" />
                                                            </g>
                                                        </svg>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </div>
                                                <div className="alert-text">Untuk melihat isi XML / Copy / Download XML Per 1 HAWB / Service Number</div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Service Number
                                                <span className="text-danger"> * </span></label>
                                            <input 
                                                type="text" 
                                                ref={inputTextServiceNumber} 
                                                className="form-control" 
                                                required 
                                                placeholder="Service Number" 
                                                onKeyUp={
                                                    (e)=> {
                                                        setTextServiceNumber(e.target.value);
                                                        if(e.target.value.length < 11){
                                                            inputTextServiceNumber.current.classList.add('is-invalid');
                                                            labelHelpText.current.classList.remove('text-muted');
                                                            labelHelpText.current.classList.add('text-danger');
                                                            labelHelpText.current.innerHTML = 'Service Number tidak valid';
                                                        }else{
                                                            inputTextServiceNumber.current.classList.remove('is-invalid');
                                                            inputTextServiceNumber.current.classList.add('is-valid');
                                                            labelHelpText.current.classList.remove('text-muted');
                                                            labelHelpText.current.classList.remove('text-danger');
                                                            labelHelpText.current.classList.add('text-success');
                                                            labelHelpText.current.innerHTML = 'Service Number valid';
                                                            setData({...data,isValid:true})
                                                        }
                                                    }
                                                }/>
                                            <span className="form-text text-muted" ref={labelHelpText}>Harap isi dengan Service Number</span>
                                        </div>
                                    </BlockUi>
                                    <div className="accordion accordion-solid accordion-toggle-plus" id="accordionExample3" ref={resultBox}>
                                        { resultApi ?
                                            resultApi.map((data,index) => {
                                                const className = `#collapseOne3${index}`;
                                                const idName = `collapseOne3${index}`;
                                                const codeString = format(data.xml,{
                                                                        indentation: '  ', 
                                                                        filter: (node) => node.type !== 'Comment', 
                                                                        collapseContent: true, 
                                                                        lineSeparator: '\n',
                                                                        whiteSpaceAtEndOfSelfclosingTag:true
                                                                    });
                                                return(
                                                    <div className="card" key={index}>
                                                        <div className="card-header" id="headingOne3">
                                                            <div className="card-title collapsed" data-toggle="collapse" data-target={className} aria-expanded="false">{data.hawb} - Created at : {converDateTime(data.created_at)} -  [{data.remarks}]</div>
                                                        </div>
                                                        <div id={idName} className="collapse" data-parent="#accordionExample3" style={{}}>
                                                            {/* <div className="card-body">
                                                                <SyntaxHighlighter language="xml" style={docco}>
                                                                    {codeString}
                                                                </SyntaxHighlighter>    
                                                            </div> */}
                                                            <pre>
                                                                <div className="card-body">{codeString}</div>
                                                            </pre>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                        }
                                        {/* <div className="card">
                                            <div className="card-header" id="headingOne3">
                                                <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseOne3" aria-expanded="false">8Y81Y7GMHNH Created at : 2020-11-25:00:00</div>
                                            </div>
                                            <div id="collapseOne3" className="collapse" data-parent="#accordionExample3" style={{}}>
                                                <div className="card-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingTwo3">
                                                <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseTwo3" aria-expanded="false">Accounting Updates</div>
                                            </div>
                                            <div id="collapseTwo3" className="collapse" data-parent="#accordionExample3" style={{}}>
                                                <div className="card-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingThree3">
                                                <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseThree3" aria-expanded="false">Latest Payroll</div>
                                            </div>
                                            <div id="collapseThree3" className="collapse" data-parent="#accordionExample3" style={{}}>
                                                <div className="card-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</div>
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary mr-2">Submit</button>
                                    {/* <button type="reset" className="btn btn-secondary">Cancel</button> */}
                                </div>
                            </form>
                            {/*end::Form*/}
                        </div>
                        {/*end::Card*/}
                    {/* </BlockUi> */}
                </div>
            </div>
        </div>
    )
}

export default ReviewXmlComponent;