import { store } from 'react-notifications-component';

const notification = {
    success : (title,message,position='center') => {
        store.addNotification({
            title: title,
            message: message,
            type: "success",
            insert: position,
            container: "center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    },
    failed : (title,message,position='center') => {
        store.addNotification({
            title: title,
            message: message,
            type: "danger",
            insert: position,
            container: "center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    },
    info : (title,message,position='center') => {
        store.addNotification({
            title: title,
            message: message,
            type: "info",
            insert: position,
            container: "center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    },
    warning : (title,message,position='center') => {
        store.addNotification({
            title: title,
            message: message,
            type: "info",
            insert: position,
            container: "center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    },
}
export default notification;