export const AuthStateReducer = (initialState = {
        isLoading:false,
        errMessage: null,
        userData:[]
    },action) => {
    switch (action.type) {
        case 'LOGIN_REQUEST':
            return {...initialState, isLoading: true, errMess: null, userData: action.payload};

        case 'LOGIN_SUCCESS':
            return {...initialState, isLoading: false, errMess: null, userData: action.payload}

        case 'LOGIN_ERROR':
            return {...initialState, isLoading: false, errMess: action.payload};

        default:
            return initialState;
    }
}

export const TicketReducer = (initialState = {
        isLoading:false,
        errMessage: null,
        ticketData:[]
    },action) => {
    switch (action.type) {
        case 'TICKET_REQUEST':
            return {...initialState, isLoading: true, errMess: null, ticketData: action.payload};

        case 'TICKET_SUCCESS':
            return {...initialState, isLoading: false, errMess: null, ticketData: action.payload}

        case 'TICKET_ERROR':
            return {...initialState, isLoading: false, errMess: action.payload};

        default:
            return initialState;
    }
}