import React from 'react';
import {Link} from 'react-router-dom';

const NavigationComponent = (props) => {
    const userDataRaw = JSON.parse(localStorage.getItem('credential'));
    const userMenu = userDataRaw.userMenu;

    let renderMenu = userMenu.map((value,index) =>{
        return (
            <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" key={index}>
                <Link to="#" className="menu-link menu-toggle">
                    <span className="menu-text">{value.name}</span>
                    <span className="menu-desc" />
                    <i className="menu-arrow" />
                </Link>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        {value.submenu.map((item,index) => {
                            return (
                                <li className="menu-item" key={index}>
                                    <Link to={item.url} className="menu-link">
                                        <span className="svg-icon menu-icon">
                                            <img className="" src={item.icon}/>
                                        </span>
                                        <span className="menu-text">{item.name}</span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </li>
        )
    })

    return (
        <div className="header-bottom">
            {/*begin::Container*/}
            <div className="container">
                {/*begin::Header Menu Wrapper*/}
                <div className="header-navs header-navs-left" id="kt_header_navs">
                    {/*begin::Tab Navs(for tablet and mobile modes)*/}
                    <ul className="header-tabs p-5 p-lg-0 d-flex d-lg-none nav nav-bold nav-tabs" role="tablist">
                        {/*begin::Item*/}
                        <li className="nav-item mr-2">
                            <Link to="#" className="nav-link btn btn-clean active" data-toggle="tab" data-target="#kt_header_tab_1" role="tab">Home</Link>
                        </li>
                        {/*end::Item*/}
                        
                    </ul>
                    {/*begin::Tab Navs*/}
                    {/*begin::Tab Content*/}
                    <div className="tab-content">
                        {/*begin::Tab Pane*/}
                        <div className="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                            {/*begin::Menu*/}
                            <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                                {/*begin::Nav*/}
                                <ul className="menu-nav">
                                    <li className="menu-item menu-item-active">
                                        <Link to={'/dashboard'} className="menu-link">
                                            <span className="menu-text">Dashboard</span>
                                        </Link>
                                    </li>
                                    {renderMenu}
                                </ul>
                                {/*end::Nav*/}
                            </div>
                            {/*end::Menu*/}
                        </div>
                        {/*begin::Tab Pane*/}
                        {/*begin::Tab Pane*/}
                        <div className="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_2">
                            <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                                {/*begin::Actions*/}
                                <Link to="#" className="btn btn-light-success font-weight-bold mr-3 my-2 my-lg-0">Latest Orders</Link>
                                <Link to="#" className="btn btn-light-primary font-weight-bold my-2 my-lg-0">Customer Service</Link>
                                {/*end::Actions*/}
                            </div>
                            <div className="d-flex align-items-center">
                                {/*begin::Actions*/}
                                <Link to="#" className="btn btn-danger font-weight-bold my-2 my-lg-0">Generate Reports</Link>
                                {/*end::Actions*/}
                            </div>
                        </div>
                        {/*begin::Tab Pane*/}
                    </div>
                    {/*end::Tab Content*/}
                </div>
                {/*end::Header Menu Wrapper*/}
            </div>
            {/*end::Container*/}
        </div>
    )
}


export default NavigationComponent;