export const setInputColor = (refInput, refLable, stringLable, color) => {
    if (color === 'danger') {
        refInput.current.classList.remove('is-valid');
        refInput.current.classList.add('is-invalid');
        refLable.current.classList.remove('text-success');
        refLable.current.classList.add('text-danger');
    } else {
        refInput.current.classList.remove('is-invalid');
        refInput.current.classList.add('is-valid');
        refLable.current.classList.add('text-success');
        refLable.current.classList.remove('text-danger');
    }
    refLable.current.classList.remove('text-muted');
    refLable.current.innerHTML = stringLable;
}