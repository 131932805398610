import React, { useContext, useState } from 'react'
import ENDPOINT from '../../config/endpoint';
import {BASE_URI,PREFIX_URI} from '../../config/config';
import Notification from '../../config/notification';
import { apiCall, buildValidationError } from '../../utils';

const HeadersContext = React.createContext();

export function useHeaders() {
    return useContext(HeadersContext)
}

export const HeadersProvider = ({ children }) => {
    const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken : ''
    
    const [dataHeaders, setDataHeaders] = useState([]);

    const getShipmentByServiceNumber = async (formData) => {
        try{
            // request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let apiCallRequestToken = apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
            let requestToken = await apiCallRequestToken

            // api call
            let getDataHeader = apiCall(requestToken.accessToken,formData.url,formData.method,formData.payload);
            let responses = await getDataHeader;
            if (responses.success) {
                setDataHeaders(responses.data)
                Notification.success('Success',responses.message,'top-right');
            } else {
                setDataHeaders([])
                const strError = responses.message.sqlMessage ? responses.message.sqlMessage : responses.message
                Notification.failed('Failed',strError,'top-right');
            }
        }catch(e) {
            setDataHeaders(e)
            console.log(e)
            Notification.failed('Failed','Please Contact Admin!','top-right');
        }
    }

    const updateShipmentData = async(formData) => {
        try{
            // request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let requestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));

            // api call
            let responses = await apiCall(requestToken.accessToken,formData.url,formData.method,formData.payload);
            if (responses.success) {
                // setDataHeaders(responses.data)
                Notification.success('Success',responses.message,'top-right');
            } else {
                // setDataHeaders([])
                if(responses.message.errors){
                    let errorString = buildValidationError(responses.message.errors);
                    Notification.failed('Failed',errorString,'top-right');
                    return;
                }
                const strError = responses.message.sqlMessage ? responses.message.sqlMessage : responses.message
                Notification.failed('Failed',strError,'top-right');
                // Notification.failed('Failed',responses.message,'top-right');
            }
        }catch(e) {
            setDataHeaders(e)
            console.log(e)
            Notification.failed('Failed','Please Contact Admin!','top-right');
        }
    }

    return (
        <HeadersContext.Provider value={{ dataHeaders:dataHeaders, getShipmentByServiceNumber, updateShipmentData }}>
            {children}
        </HeadersContext.Provider>
    )
}
