import React,{ useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Form, Container, Col, Dropdown, Row } from "react-bootstrap";
import {BASE_URI,PREFIX_URI,ENDPOINT,Notification} from '../../../config';
import {apiCall, buildValidationError} from '../../../utils';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const $ = require('jquery')
$.Datatable = require('datatables.net')

const MenuTable = ({ tableRef }) => {
    const USERNAME = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).userData.name:''
    const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken : ''
    const [modal,setModal] = useState({
        isShowModalUpdate:false,
        isShowModalResponse:false,
        data:null,
    });

    const [textInput, setTextInput] = useState({
        textInputNama:'',
        textInputEmail:'',
        textInputNoTlp:'',
        textInputAlamat:'',
        textInputPassword:'',
        textInputConfirmPassword:'',
        textInputIsAdmin:null
    });

    const tableElement = tableRef;

    useEffect(()=>{

        const handleUpdateButton = async(id_user) => {
            try{
                //request new Token
                const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
                let requestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
        
                const url = BASE_URI+PREFIX_URI+ENDPOINT.USER_FIND_USER;
                const rawBody = JSON.stringify({id_user:id_user});
                let responses = await apiCall(requestToken.accessToken,url,'POST',rawBody);
                if(!responses.success){
                    if(responses.message.errors){
                        let errorString = buildValidationError(responses.message.errors);
                        Notification.failed('Failed',errorString,'top-right');
                        return;
                    }
                    return Notification.failed('Failed',responses.message,'top-right');
                }else{
                    setModal({...modal,isShowModalUpdate:true,data:id_user});
                    if(responses.data != null || responses.data.length != 0){
                        setTextInput({
                            ...textInput,
                            textInputEmail:responses.data[0].email,
                            textInputNama:responses.data[0].name,
                            textInputNoTlp:responses.data[0].phone_number,
                            textInputAlamat:responses.data[0].address,
                            textInputIsAdmin:responses.data[0].is_admin == "1" ? true:false,
                        })
                        return;
                    }
                    return Notification.failed('Failed','Data Not Found!','top-right');
                }
            }catch(e){
                console.log(e)
            }

        }

        const handleDeleteButton = (id,email) => {
            Swal.fire({
                title: `User ${email} Yakin ingin dihapus?`,
                text: "Data tidak bisa dikembalikan!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, Hapus!',
                cancelButtonText:'Batal'
            }).then(async(result) => {
                if (result.isConfirmed) {
                    try{
                        //request new Token
                        const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
                        let apiCallRequestToken = apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
                        let requestToken = await apiCallRequestToken
                        
                        const url = `${BASE_URI+PREFIX_URI+ENDPOINT.USER_DELETE_USER}/${id}`;
                        let responses = await apiCall(requestToken.accessToken,url,'DELETE',JSON.stringify({action_by:USERNAME}));
    
                        if(!responses.success){
                            if(responses.message.errors !== null) return Notification.failed('Failed',buildValidationError(responses.message.errors),'top-right');
                            return Notification.failed('Failed',responses.message,'top-right');
                        }
                        $(tableElement.current).DataTable().ajax.reload();
                        return Notification.success('Success',responses.message,'top-right');

                    }catch(e){
                        console.log(e)
                        Notification.failed('Failed',e.toString(),'top-right');
                    }
                }
            })
        }

        $(tableElement.current).DataTable(
            {
                ajax: {
                    url : BASE_URI+PREFIX_URI+ENDPOINT.MENU_LIST_MENU,
                    method:'POST',
                    data:{
                        table:'m_users_menu',
                        // parameter:[{
                        //     key:'is_active',
                        //     value:'1'
                        // }],
                    }
                },
                serverSide:true,
                columns: [
                    { "name": "name" },
                    // { "name": "icon" },
                    // { "name": "url" },
                    { "name": "is_active",render:(data,type,full,meta) => {
                        let status = {
                            1: {'title': 'Active', 'class': ' label-light-success'},
                            0: {'title': 'Not Active', 'class': ' label-light-danger'},
                        }
                        if (typeof status[data] === 'undefined') {
                            return data;
                        }
                        return '<span class="label label-lg font-weight-bold' + status[data].class + ' label-inline">' + status[data].title + '</span>';
                    }},
                    {"name": "id"}
                ],
                columnDefs:[
                    {
                        targets:[2],
                        className:"center",
                        createdCell:(td,cellData,rowData)=> {
                            ReactDOM.render(
                                <div>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <i className="flaticon-edit"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleUpdateButton(rowData[2]) } >Update</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleDeleteButton(rowData[2],rowData[1]) } >Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>,td
                            );
                        }
                    }
                ]
            }
        )
    },[])

    const handleCloseModal = () => {
        setModal({
            ...modal,
            data:null,
            isShowModalUpdate:false,
            isShowModalResponse:false
        })
    }

    const handleSubmitUpdate = async(e) => {
        e.preventDefault();
        try{
            //request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let requestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
    
            const url = `${BASE_URI+PREFIX_URI+ENDPOINT.USER_UPDATE_USER}/${modal.data}`;
            const rawBody = JSON.stringify({
                email:textInput.textInputEmail,
                name:textInput.textInputNama,
                address:textInput.textInputAlamat,
                password:textInput.textInputPassword,
                password_confirmation:textInput.textInputConfirmPassword,
                phone:textInput.textInputNoTlp,
                is_admin:textInput.textInputIsAdmin ? 1 : 0,
                action_by:USERNAME,
            });
            let responses = await apiCall(requestToken.accessToken,url,'PUT',rawBody);
            if(!responses.success){
                if(responses.message.errors !== null) return Notification.failed('Failed',buildValidationError(responses.message.errors),'top-right');
                return Notification.failed('Failed',responses.message,'top-right');
            }
            handleCloseModal();
            $(tableElement.current).DataTable().ajax.reload();
            return Notification.success('Success',responses.message,'top-right');
        }catch(e){
            console.log(e)
            return Notification.failed('Failed',e,'top-right');
        }
    }

    return(
        <div>
            <table className="table table-bordered table-hover dataTable" id="dataTable" style={{marginTop: '13px !important'}} ref={tableRef}>
                <thead>
                    <tr>
                        <th>Name</th>
                        {/* <th>Icon</th>
                        <th>Url</th> */}
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
            </table>
            <Modal 
                show={modal.isShowModalUpdate} 
                centered 
                onHide={handleCloseModal}
                size='lg'
                // dialogClassName='modal-90w'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Data User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form onSubmit={handleSubmitUpdate}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Nama</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter Nama"
                                    onChange={e => setTextInput({...textInput,textInputNama:e.target.value})}
                                    value={textInput.textInputNama}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Enter email" 
                                    onChange={e => setTextInput({...textInput,textInputEmail:e.target.value})}
                                    value={textInput.textInputEmail}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicTlp">
                                <Form.Label>No Tlp</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter No Tlp" 
                                    onChange={e => setTextInput({...textInput,textInputNoTlp:e.target.value})}
                                    value={textInput.textInputNoTlp}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicAlamat">
                                <Form.Label>Alamat</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Alamat" 
                                    onChange={e => setTextInput({...textInput,textInputAlamat:e.target.value})}
                                    value={textInput.textInputAlamat}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Password" 
                                    onChange={e => setTextInput({...textInput,textInputPassword:e.target.value})}
                                    value={textInput.textInputPassword}
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Ulangi Password" 
                                    onChange={e => setTextInput({...textInput,textInputConfirmPassword:e.target.value})}
                                    value={textInput.textInputConfirmPassword}
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check 
                                    type="checkbox" 
                                    label="Set Admin"
                                    defaultChecked={textInput.textInputIsAdmin}
                                    checked={textInput.textInputIsAdmin}
                                    value={textInput.textInputIsAdmin}
                                    onChange={(e) => setTextInput({...textInput,textInputIsAdmin:e.target.checked})} 
                                />
                            </Form.Group>
                            <Col>
                                <Row>
                                    <Button variant="primary" type="submit" >Submit</Button>
                                    <Button variant="secondary" className="float-right" type="button" onClick={handleCloseModal}>Close</Button>
                                </Row>
                            </Col>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default MenuTable;