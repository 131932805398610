import React, { useState, useRef } from 'react';
import BlockUi from 'react-block-ui';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import Notification from '../../../config/notification';
import ENDPOINT from '../../../config/endpoint';
import {BASE_URI,PREFIX_URI} from '../../../config/config';

import {postTicket} from '../../../redux/ActionCreators';
import { connect } from 'react-redux';
import {buildValidationError,apiCall} from '../../../utils';

const FormTicketComponent = (props) => {
    const USERNAME = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).userData.name:''
    const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken:''
    const [isFile, setIsFile] = useState([]);
    const [data,setData] = useState({
        textInputJudul:'',
        textInputPrioritas:'',
        textInputKategori:'',
        textInputKeterangan:'',
    });
    const formRefs = useRef()
    const getUploadParams = ({ meta }) => { 
        const url = BASE_URI+PREFIX_URI+ENDPOINT.UPLOAD_FILE_POST;
        return { url: url + '?dirPath=ticket' }
    };
    const handleChangeStatus = async({ meta, file,xhr }, status) => { 
        switch (status) {
            case 'exception_upload':
                Notification.failed('Failed','Gagal Upload File!');
                break;
            case 'error_upload':
                Notification.failed('Failed','Gagal Upload File!');
                break;
            case 'done':
                const responses = JSON.parse(xhr.responseText);
                const {destination,filename} = responses.data;
                const dirPath = destination.split("/");
                let file_name = isFile;
                let object = {id:meta.id,path:dirPath[2],filename:filename};
                file_name.push(object);
                setIsFile(file_name);
                Notification.success('Success','File sudah di upload!');
                break;
            case 'removed':
                const index = isFile.findIndex(v => v.id === meta.id);
                
                const rawBody = JSON.stringify({"dirpath":isFile[index].path,"filename":isFile[index].filename});
                const url = BASE_URI+PREFIX_URI+ENDPOINT.UPLOAD_FILE_DELETE;
                const responsesDelete = await apiCall(TOKEN,url,'DELETE',rawBody)
                
                if(!responsesDelete.success){
                    // error validasi
                    if(responsesDelete.message.errors){
                        let errorString = buildValidationError(responsesDelete.message.errors);
                        Notification.failed('Failed',errorString,'top-right');
                        return;
                    }
                    Notification.failed('Failed',responsesDelete.message,'top-right');
                }else{
                    const newList = isFile.filter((value) =>{
                        return value.id !== meta.id
                    });
                    setIsFile(newList);
                    Notification.success('Success','File sudah di hapus!');
                }
                break;
        
            default:
                break;
        }
    }

    const handleSubmitTicket = async() => {
        const {textInputJudul,textInputKategori,textInputKeterangan,textInputPrioritas} = data;
        // const {} = isFile;
        // props.mPostTicket(textInputJudul,textInputPrioritas,textInputKategori,textInputKeterangan,JSON.stringify(isFile));

        try{
            //request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let requestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
    
            const url = BASE_URI+PREFIX_URI+ENDPOINT.TICKET_CREATE_TICKET;
            const rawBody = JSON.stringify({'title':textInputJudul,'priority':textInputPrioritas,'category':textInputKategori,'remarks':textInputKeterangan,'attachment':JSON.stringify(isFile),'username':USERNAME});
            let responses = await apiCall(requestToken.accessToken,url,'POST',rawBody);
            console.log(responses);
    
            if(!responses.success){
                // error validasi
                if(responses.message.errors){
                    let errorString = buildValidationError(responses.message.errors);
                    Notification.failed('Failed',errorString,'top-right');
                    return;
                }
                Notification.failed('Failed',responses.message,'top-right');
            }else{
                setIsFile([]);
                setData({...data,textInputJudul:'',
                textInputPrioritas:'',
                textInputKategori:'',
                textInputKeterangan:''});
                document.getElementById("formTicket").reset();
                Notification.success('Success','Ticket Created!','top-right');
            }
        }catch(e){
            console.log(e)
            Notification.failed('Failed','Please contact Admin!','top-right');
        }

    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md">
                    <BlockUi tag="div" blocking={props.TicketState.isLoading} renderChildren={false}>
                    {/*begin::Card*/}
                    <div className="card card-custom gutter-b example example-compact">
                        <div className="card-header">
                            <h3 className="card-title">Buat Tiket</h3>
                        </div>
                        <form onSubmit={e=>{e.preventDefault();handleSubmitTicket()}} id="formTicket" ref={formRefs}>
                            <div className="card-body">
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label text-lg-right">Judul:</label>
                                    <div className="col-lg-7">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Judul Tiket" 
                                            name="tiket_judul" 
                                            id="tiket_judul"
                                            value={data.textInputJudul}
                                            onChange={(e) => setData({...data,textInputJudul:e.target.value})} 
                                        />
                                        {/* <span className="form-text text-muted">Please enter your full name</span> */}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label text-lg-right">Prioritas:</label>
                                    <div className="col-lg-7">
                                        {/* <input type="email" className="form-control" placeholder="Enter email" name="email" /> */}
                                        <select
                                            name="tiket_prioritas"
                                            id="tiket_prioritas"
                                            className="form-control"
                                            onChange={(e) => setData({...data,textInputPrioritas:e.target.value})}
                                        >
                                            <option value="">Pilih</option>
                                            <option value="0">Low</option>
                                            <option value="1">Medium</option>
                                            <option value="2">High</option>
                                            <option value="3">Urgent</option>
                                        </select>
                                        <span className="form-text text-muted">Mohon pilih untuk di prioritaskan</span>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label text-lg-right">Kategori:</label>
                                    <div className="col-lg-7">
                                        {/* <input type="email" className="form-control" placeholder="Enter email" name="email" /> */}
                                        <select
                                            name="tiket_kategori"
                                            id="tiket_kategori"
                                            className="form-control"
                                            onChange={(e) => setData({...data,textInputKategori:e.target.value})}
                                        >
                                            <option value="">Pilih</option>
                                            <option value="cas">CIAS 2 - CAS</option>
                                            <option value="gtln">CIAS 2 - GTLN</option>
                                            <option value="xsys">CIAS 2 - XSYS</option>
                                            <option value="aei">CIAS 2 - AEI</option>
                                        </select>
                                        <span className="form-text text-muted">Mohon pilih untuk aplikasi yang mana</span>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label text-lg-right">Keterangan:</label>
                                    <div className="col-lg-7">
                                        <textarea 
                                            className="form-control" 
                                            rows={3} 
                                            placeholder="Please enter your message" 
                                            // defaultValue={""}
                                            defaultValue={data.textInputKeterangan}
                                            onChange={(e) => setData({...data,textInputKeterangan:e.target.value})}
                                        />
                                        <span className="form-text text-muted">Isi untuk menjelaskan permasalahan yang terjadi</span>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label text-lg-right">Lampiran:</label>
                                    <div className="col-lg-7">
                                        <div className="dropzone dropzone-multi" id="kt_dropzone_5">
                                            {/* <div className="dropzone-panel mb-lg-0 mb-2">
                                                <a className="dropzone-select btn btn-light-primary font-weight-bold btn-sm dz-clickable">Pilih Lampiran</a>
                                            </div> */}
                                            <Dropzone
                                                SubmitButtonComponent={null}
                                                inputContent="Geser File Atau Klik disini untuk mencari file"
                                                inputWithFilesContent="Tambah File"
                                                // PreviewComponent={null}
                                                // addClassNames="form-control"
                                                // classNames={{ dropzone: "form-control" }}
                                                getUploadParams={getUploadParams}
                                                onChangeStatus={handleChangeStatus}
                                                canCancel={false}
                                                onSubmit={handleSubmitTicket}
                                                // accept="image/*,audio/*,video/*"
                                                accept="image/*"
                                            />
                                            {/* <div className="dropzone-items">
                                            </div>
                                            <div className="dz-default dz-message">
                                                <button className="dz-button" type="button">Drop files here to upload</button>
                                            </div> */}
                                        </div>
                                        {/* <span className="form-text text-muted">Max file size is 1MB and max number of files is 5.</span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-lg-3" />
                                    <div className="col-lg-9">
                                        <button type="submit" className="btn btn-light-primary mr-2">Submit</button>
                                        <button type="reset" className="btn btn-primary">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    {/*end::Card*/}
                    </BlockUi>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        TicketState: state.TicketState
    }
}

const mapDispatchToProps = dispatch => ({
    mPostTicket: (title,priority,category,remarks,attachment) => dispatch(postTicket(title,priority,category,remarks,attachment)),
});

// export default FormTicketComponent;
export default connect(mapStateToProps,mapDispatchToProps)(FormTicketComponent);