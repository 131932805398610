import { scriptLoader } from './scriptLoarder';
export const apiCall = async(token,url,method,payload) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    let requestOptions = '';
    if(method === 'GET'){
        requestOptions = {
            method: method,
            headers: myHeaders,
            redirect: 'follow'
        };
    }else{
        requestOptions = {
            method: method,
            headers: myHeaders,
            body: payload,
            redirect: 'follow'
        };
    }

    let loginApiCall = await fetch( url, requestOptions);
    let responses = loginApiCall.status === 204 ? '' : await loginApiCall.json();
    return responses;
}

export const buildValidationError = (responseError) => {
    const errorRaw = responseError;
    const label = Object.keys(errorRaw);
    let errorString = '';
    for(var i=0;i<label.length;i++){
        errorString += label[i] +' : '+ errorRaw[label[i]].join() + '\n';
    }
    console.log(errorString);
    return errorString;
}

// export const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken:''
// export const USERNAME = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).userData.name:''
// export const USERDATA = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).userData : ''
const isAdmin = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).userData.isAdmin:'';
export const ISADMIN = isAdmin !== '' ? isAdmin == 1 ? true : false : false

export const converDate = (date) => {
    const dateString = new Date(date).toISOString().replace(/T/, ' ').replace(/\..+/, '');
    return dateString.substring(0,10)
}
export const converDateTime = (date) => {
    const dateString = new Date(date).toISOString().replace(/T/, ' ').replace(/\..+/, '');
    return dateString.substring(0,20)
}

export { scriptLoader }