import * as AuthService from "../Components/service/AuthService";
// import Notification from '../../config/notification';
import Notification from '../config/notification';
import ENDPOINT from '../config/endpoint';
import {BASE_URI,PREFIX_URI} from '../config/config';
import {buildValidationError,apiCall} from '../utils';
export const postLogin = (email,password) => async(dispatch) => {
    // dispatch({type:'LOGIN_REQUEST'})
    // setTimeout(() => {
    //     // console.log(email,password);
    //     dispatch({type:'LOGIN_SUCCESS',payload:{usename:"ipung",age:26}})
    // },2000)
    try{
        dispatch({type:'LOGIN_REQUEST'})
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({'password':password,'email':email}),
            redirect: 'follow'
        };

        const url = BASE_URI + PREFIX_URI + ENDPOINT.LOGIN;
        let loginApiCall = await fetch( url, requestOptions);
        let responses = await loginApiCall.json();
        if(!loginApiCall.ok){
            dispatch({type:'LOGIN_ERROR',payload:responses.message.errors})
            // error validasi
            if(responses.message.errors){
                // const errorRaw = responses.message.errors;
                // const label = Object.keys(errorRaw);
                // let errorString = '';
                // for(var i=0;i<label.length;i++){
                // errorString += label[i] +' : '+ errorRaw[label[i]].join() + '\n';
                // }
                const errorString = buildValidationError(responses.message.errors);
                Notification.failed('Failed',errorString,'top-right');
                return;
            }
            Notification.failed('Failed',responses.message,'top-right');
        }else{
            Notification.success('Success','Login Success!','top-right');
            dispatch({type:'LOGIN_SUCCESS',payload:responses.data});
            AuthService.login(JSON.stringify(responses.data));
            // setData({...data,isLogin:true});
            // history.push('/')
        }
    }catch(error){
        console.log(error);
    }
    return
}

export const postTicket = (title, priority, category, remarks, attachment) => async (dispatch) => {
    const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken : ''
    try{
        dispatch({type:'TICKET_REQUEST'});

        //request new Token
        const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
        let requestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));

        const url = BASE_URI+PREFIX_URI+ENDPOINT.TICKET_CREATE_TICKET;
        const rawBody = JSON.stringify({'title':title,'priority':priority,'category':category,'remarks':remarks,'attachment':attachment});
        let responses = await apiCall(requestToken.accessToken,url,'POST',rawBody);
        console.log(responses);

        if(!responses.success){
            dispatch({type:'TICKET_ERROR',payload:responses.message});
            // error validasi
            if(responses.message.errors){
                let errorString = buildValidationError(responses.message.errors);
                Notification.failed('Failed',errorString,'top-right');
                return;
            }
            Notification.failed('Failed',responses.message,'top-right');
        }else{
            dispatch({type:'TICKET_SUCCESS',payload:responses.message.errors})
            Notification.success('Success','Ticket Created!','top-right');
        }
    }catch(error){
        console.log(error);
    }
    return
}