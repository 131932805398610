import React, { useContext, useState } from 'react'
import ENDPOINT from '../../config/endpoint';
import {BASE_URI,PREFIX_URI} from '../../config/config';
import Notification from '../../config/notification';
import { apiCall, buildValidationError } from '../../utils';

const ItemsContext = React.createContext();

export const useItems = () => {
    return useContext(ItemsContext);
}

export const ItemsProvider = ({ children }) => {
    const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken : ''
    
    const [listItems, setListItems] = useState([]);

    const getListItems = async (formData) => {
        try{
            // request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let apiCallRequestToken = apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
            let requestToken = await apiCallRequestToken

            // api call
            let getDataHeader = apiCall(requestToken.accessToken,formData.url,formData.method,formData.payload);
            let responses = await getDataHeader;
            if (responses.success) {
                setListItems(responses.data)
                Notification.success('Success',responses.message,'top-right');
            } else {
                setListItems([])
                const strError = responses.message.sqlMessage ? responses.message.sqlMessage : responses.message
                Notification.failed('Failed',strError,'top-right');
                // Notification.failed('Failed',responses.message,'top-right');
            }
        }catch(e) {
            setListItems([])
            console.log(e)
            Notification.failed('Failed','Please Contact Admin! ['+ e.message.sqlMessage +']','top-right');
        }
    }

    const updateDataItem = async(formData) => {
        // console.log(formData);
        try{
            // request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let apiCallRequestToken = apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
            let requestToken = await apiCallRequestToken

            // api call
            let getDataHeader = apiCall(requestToken.accessToken,formData.url,formData.method,formData.payload);
            let responses = await getDataHeader;
            if (responses.success) {
                // setListItems([])
                setListItems(responses.data)
                Notification.success('Success',responses.message,'top-right');
            } else {
                setListItems([])
                if(responses.message.errors){
                    let errorString = buildValidationError(responses.message.errors);
                    Notification.failed('Failed',errorString,'top-right');
                    return;
                }
                const strError = responses.message.sqlMessage ? responses.message.sqlMessage : responses.message
                Notification.failed('Failed',strError,'top-right');
            }
        }catch(e) {
            setListItems([])
            // Notification.failed('Failed', 'Please Contact Admin!', 'top-right');
            Notification.failed('Failed','Please Contact Admin! ['+ e.message.sqlMessage +']','top-right');
        }
    }

    const deleteDataItem = async (formData) => {
        console.log(formData);
        try{
            // request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let apiCallRequestToken = apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
            let requestToken = await apiCallRequestToken

            // api call
            let getDataHeader = apiCall(requestToken.accessToken,formData.url,formData.method,formData.payload);
            let responses = await getDataHeader;
            if (responses.success) {
                // setListItems([])
                setListItems(responses.data)
                Notification.success('Success',responses.message,'top-right');
            } else {
                setListItems([])
                if(responses.message.errors){
                    let errorString = buildValidationError(responses.message.errors);
                    Notification.failed('Failed',errorString,'top-right');
                    return;
                }
                const strError = responses.message.sqlMessage ? responses.message.sqlMessage : responses.message
                Notification.failed('Failed',strError,'top-right');
            }
        }catch(e) {
            setListItems([])
            // Notification.failed('Failed', 'Please Contact Admin!', 'top-right');
            Notification.failed('Failed','Please Contact Admin! ['+ e.message.sqlMessage +']','top-right');
        }
    }

    return (
        <ItemsContext.Provider value={{ listItems:listItems, getListItems, updateDataItem, deleteDataItem }}>
            {children}
        </ItemsContext.Provider>
    )
}
