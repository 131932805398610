export const scriptLoader = () => {
    window.KTUtil.ready(function() {
        ////////////////////////////////////////////////////
        // Layout Base Partials(mandatory for core layout)//
        ////////////////////////////////////////////////////

        // Init Desktop & Mobile Headers
        window.KTLayoutHeader.init('kt_header', 'kt_header_mobile');

        // Init Header Menu
        window.KTLayoutHeaderMenu.init('kt_header_menu', 'kt_header_navs');

        // Init Header Topbar For Mobile Mode
        window.KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle');

        // Init Aside
        window.KTLayoutAside.init('kt_aside');

        // Init Aside Menu
        window.KTLayoutAsideMenu.init('kt_aside_menu');

        // Init Subheader
        window.KTLayoutSubheader.init('kt_subheader');

        // Init Content
        window.KTLayoutContent.init('kt_content');

        // Init Footer
        window.KTLayoutFooter.init('kt_footer');

        //////////////////////////////////////////////
        // Layout Extended Partials(optional to use)//
        //////////////////////////////////////////////


        // Init Scrolltop
        window.KTLayoutScrolltop.init('kt_scrolltop');

        // Init Sticky Card
        window.KTLayoutStickyCard.init('kt_page_sticky_card');

        // Init Stretched Card
        window.KTLayoutStretchedCard.init('kt_page_stretched_card');

        // Init Code Highlighter & Preview Blocks(used to demonstrate the theme features)
        window.KTLayoutExamples.init();

        // Init Demo Selection Panel
        window.KTLayoutDemoPanel.init('kt_demo_panel');

        // Init Chat App(quick modal chat)
        window.KTLayoutChat.init();

        // Init Quick Actions Offcanvas Panel
        window.KTLayoutQuickActions.init('kt_quick_actions');

        // Init Quick Notifications Offcanvas Panel
        window.KTLayoutQuickNotifications.init('kt_quick_notifications');

        // Init Quick Offcanvas Panel
        window.KTLayoutQuickPanel.init('kt_quick_panel');

        // Init Quick Search Panel
        window.KTLayoutQuickSearch.init('kt_quick_search');

        // Init Quick User Panel
        window.KTLayoutQuickUser.init('kt_quick_user');

        // Init Search For Quick Search Dropdown
        window.KTLayoutSearch().init('kt_quick_search_dropdown');

        // Init Search For Quick Search Offcanvas Panel
        window.KTLayoutSearchOffcanvas().init('kt_quick_search_offcanvas');

        // Widget
        window.KTWidgets.init(window.KTAppSettings);
        // App
        window.KTApp.init(window.KTAppSettings);
    });
}