import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Form, Container,Col ,Row } from "react-bootstrap";
import UserTableFunction from './UserTableFunction';

import {BASE_URI, PREFIX_URI, ENDPOINT, Notification} from '../../../config';
import {apiCall, TOKEN, USERNAME, buildValidationError} from '../../../utils';

const $ = require('jquery')
$.Datatable = require('datatables.net')

const UserComponent = () => {
    const USERNAME = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).userData.name:''
    const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken:''
    const tableRef = useRef();
    const [modalShow,setModalShow] = useState(false);
    const [isUserExist,setIsUserExist] = useState(false);
    const [textInput, setTextInput] = useState({
        textInputNama:'',
        textInputEmail:'',
        textInputNoTlp:'',
        textInputAlamat:'',
        textInputPassword:'',
        textInputConfirmPassword:''
    });
    const handleCloseButton = () => {
        setModalShow(false)
    }

    const handleCheckingEmail = async() => {
        try{
            //request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let requestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
    
            const url = BASE_URI+PREFIX_URI+ENDPOINT.USER_FIND_USER;
            const rawBody = JSON.stringify({'email':textInput.textInputEmail});
            let responses = await apiCall(requestToken.accessToken,url,'POST',rawBody);
            if(responses.success){
                setIsUserExist(true);
                Notification.failed('Failed','User already exist!','top-right');
            }else{
                setIsUserExist(false);
            }
        }catch(e){
            console.log(e)
        }
    }

    const handleSubmit = async(e) =>{
        e.preventDefault();
        if(isUserExist) return Notification.failed('Failed','User already exist!','top-right')
        try{
            //request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let requestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
    
            const url = BASE_URI+PREFIX_URI+ENDPOINT.USER_CREATE_USER;
            const payload = {
                email: textInput.textInputEmail,
                name: textInput.textInputNama,
                phone: textInput.textInputNoTlp,
                password: textInput.textInputPassword,
                password_confirmation: textInput.textInputConfirmPassword,
                address: textInput.textInputAlamat,
                username: USERNAME
            }
            const rawBody = JSON.stringify(payload);
            let responses = await apiCall(requestToken.accessToken,url,'POST',rawBody);
            if(!responses.success){
                if(responses.message.errors){
                    let errorString = buildValidationError(responses.message.errors);
                    Notification.failed('Failed',errorString,'top-right');
                    return;
                }
                return Notification.failed('Failed',responses.message,'top-right');
            }else{
                setModalShow(false)
                $(tableRef.current).DataTable().ajax.reload();
                return Notification.success('Success',responses.message,'top-right');
            }
        }catch(e){
            console.log(e)
        }
    }

    return(
        <div className="container">
            {/*begin::Card*/}
            <div className="card card-custom">
                <div className="card-header">
                    <div className="card-title">
                        <span className="card-icon">
                            <i className="flaticon2-user-1 text-primary" />
                        </span>
                        <h3 className="card-label">List User</h3>
                    </div>
                    <div className="card-toolbar">
                        {/*begin::Button*/}
                        <Link 
                            to="#" 
                            onClick={() => {
                                setModalShow(true);
                                setTextInput({
                                    ...textInput,
                                    textInputNama:'',
                                    textInputEmail:'',
                                    textInputNoTlp:'',
                                    textInputPassword:'',
                                    textInputConfirmPassword:'',
                                })
                            }} 
                            className="btn btn-primary font-weight-bolder">
                            <span className="svg-icon svg-icon-md">
                                {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg*/}
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <circle fill="#000000" cx={9} cy={15} r={6} />
                                        <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3" />
                                    </g>
                                </svg>
                                {/*end::Svg Icon*/}
                            </span>New Record
                        </Link>
                        {/*end::Button*/}
                    </div>
                </div>
                <div className="card-body">
                    {/*begin: Datatable*/}
                    {/* <UserTable data=""/> */}
                    <UserTableFunction tableRef={tableRef}/>
                    {/* <table className="table table-bordered table-hover table-checkable" id="kt_datatable" style={{marginTop: '13px !important'}}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>email</th>
                                <th>No Tlp</th>
                                <th>Last Login</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                    </table> */}
                    {/*end: Datatable*/}
                </div>
            </div>
            {/*end::Card*/}

            <Modal 
                show={modalShow} 
                centered 
                onHide={handleCloseButton}
                size='lg'
                // dialogClassName='modal-90w'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Nama</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter Nama"
                                    onChange={e => setTextInput({...textInput,textInputNama:e.target.value})}
                                    value={textInput.textInputNama}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Enter email" 
                                    onChange={e => setTextInput({...textInput,textInputEmail:e.target.value})}
                                    onBlur={handleCheckingEmail}
                                    value={textInput.textInputEmail}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicTlp">
                                <Form.Label>No Tlp</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter No Tlp" 
                                    onChange={e => setTextInput({...textInput,textInputNoTlp:e.target.value})}
                                    value={textInput.textInputNoTlp}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicAlamat">
                                <Form.Label>Alamat</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Alamat" 
                                    onChange={e => setTextInput({...textInput,textInputAlamat:e.target.value})}
                                    value={textInput.textInputAlamat}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Password" 
                                    onChange={e => setTextInput({...textInput,textInputPassword:e.target.value})}
                                    value={textInput.textInputPassword}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Ulangi Password" 
                                    onChange={e => setTextInput({...textInput,textInputConfirmPassword:e.target.value})}
                                    value={textInput.textInputConfirmPassword}
                                    required
                                />
                            </Form.Group>
                            <Col>
                                <Row>
                                    <Button variant="primary" type="submit" onClick={handleSubmit}>Submit</Button>
                                    <Button variant="secondary" className="float-right" type="button" onClick={handleCloseButton}>Close</Button>
                                </Row>
                            </Col>
                        </Form>
                    </Container>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseButton}>
                        Close
                    </Button>
                    <Button variant="primary">Save changes</Button>
                </Modal.Footer> */}
            </Modal>

        </div>

    )
}

export default UserComponent;