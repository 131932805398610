import React, { useContext, useState } from 'react'
import ENDPOINT from '../../config/endpoint';
import {BASE_URI,PREFIX_URI} from '../../config/config';
import Notification from '../../config/notification';
import { apiCall, buildValidationError } from '../../utils';

const UsersContext = React.createContext();

export const useUsers = () => {
    return useContext(UsersContext);
}

export const UsersProvider = ({ children }) => {
    const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken : ''
    
    const [dataUpdated, setDataUpdated] = useState(false)

    const updateAsignMenu = async(formData) => {
        try {
            const refreshToken = JSON.parse(localStorage.getItem("credential")).refreshToken;
            // console.log("localStorage", JSON.parse(localStorage.getItem("credential")).refreshToken);
            // request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let requestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:refreshToken}));

            // api call
            let responses = await apiCall(requestToken.accessToken,formData.url,formData.method,formData.payload);
            if (responses.success) {
                Notification.success('Success',responses.message,'top-right');
            } else {
                if(responses.message.errors){
                    let errorString = buildValidationError(responses.message.errors);
                    Notification.failed('Failed',errorString,'top-right');
                    return;
                }
                const strError = responses.message.sqlMessage ? responses.message.sqlMessage : responses.message
                Notification.failed('Failed',strError,'top-right');
            }
        }catch(e) {
            console.log(e)
            Notification.failed('Failed','Please Contact Admin!','top-right');
        }
    }

    return (
        <UsersContext.Provider value={{ updateAsignMenu }}>
            {children}
        </UsersContext.Provider>
    )
}
