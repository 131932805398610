import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, Dropdown ,Image, Container,Col ,Row } from "react-bootstrap";
import { format } from 'date-fns';

import ENDPOINT from '../../../config/endpoint';
import {BASE_URI,PREFIX_URI} from '../../../config/config';
import Notification from '../../../config/notification';
import '../../../css/dataTables.bootstrap4.min.css';
import {apiCall,buildValidationError,ISADMIN} from '../../../utils';

const $ = require('jquery')
$.Datatable = require('datatables.net')

const TicketTableFunction = () => {
    const USERNAME = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).userData.name:''
    const TOKEN = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).refreshToken : ''
    const isAdmin = localStorage.getItem('credential') ? JSON.parse(localStorage.getItem('credential')).userData.isAdmin:'';
    const ISADMIN = isAdmin !== '' ? isAdmin == 1 ? true : false : false
    const tableEl = useRef();
    const [modal,setModal] = useState({
        isShowModalAttchment:false,
        isShowModalResponse:false,
        data:null,
    });

    const [textMessage,setTextMessage] = useState('');
    const [listMessage,setListMessage] = useState([]);

    const el = tableEl.current;
    useEffect(()=>{
        // console.log(tableEl.current);
        // const el = tableEl.current;

        const handleReplyButton = async(id) => {
            try{
                setModal({...modal,isShowModalResponse:true,data:id})
                //request new Token
                const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
                let apiCallRequestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
    
                // api call
                const url = BASE_URI+PREFIX_URI+ENDPOINT.TICKET_GET_LIST_MESSAGE_TICKET;
                let responses = await apiCall(apiCallRequestToken.accessToken,url,'POST',JSON.stringify({'ticket_id':id}));
                if(!responses.success){
                    if(responses.message.errors){
                        let errorString = buildValidationError(responses.message.errors);
                        Notification.failed('Failed',errorString,'top-right');
                        return;
                    }
                    Notification.failed('Failed',responses.message,'top-right');
                }else{
                    if(typeof responses.data !== 'undefined'){
                        setListMessage(responses.data);
                    }
                    Notification.success('Success',responses.message,'top-right');
                }
            }catch(error){
                console.log(error)
            }
        }

        const handleCloseButton = async(id) => {
            //request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let apiCallRequestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));

            // api call
            const url = BASE_URI+PREFIX_URI+ENDPOINT.TICKET_UPDATE_TICKET;
            let responses = await apiCall(apiCallRequestToken.accessToken,url,'PUT',JSON.stringify({'ticket_id':id}));
            if(!responses.success){
                if(responses.message.errors){
                    let errorString = buildValidationError(responses.message.errors);
                    Notification.failed('Failed',errorString,'top-right');
                    return;
                }
                Notification.failed('Failed',responses.message,'top-right');
            }else{
                Notification.success('Success',responses.message,'top-right');
                $(tableEl.current).DataTable().ajax.reload();
            }
        }

        const parameter = ISADMIN ? []: [
            {
                key:'created_by',
                value:USERNAME
            }
        ]

        $(tableEl.current).DataTable(
            {
                ajax: {
                    url : BASE_URI+PREFIX_URI+ENDPOINT.TICKET_GET_LIST_TICKET,method:'POST',
                    data:{
                        parameter:parameter,
                        table:'t_ticket'
                    }
                },
                serverSide:true,
                columns: [
                    { "name": "title" },
                    { "name": "priority" },
                    { "name": "category" },
                    { "name": "remarks" },
                    { "name": "attachment" },
                    { "name": "status_ticket",render:(data,type,full,meta) => {
                        let status = {
                            1: {'title': 'Closed', 'class': ' label-light-success'},
                            0: {'title': 'Open', 'class': ' label-light-danger'},
                        }
                        if (typeof status[data] === 'undefined') {
                            return data;
                        }
                        return '<span class="label label-lg font-weight-bold' + status[data].class + ' label-inline">' + status[data].title + '</span>';
                    }},
                    { "name": "last_reply" },
                    { "name": "created_by" },
                    { "name": "created_at" },
                    { "name": "id" },
                ],
                columnDefs:[
                    {
                        targets:[9],
                        className:"center",
                        createdCell:(td,cellData,rowData)=> {
                            const visibility = rowData[5] == 1 ? true:false
                            ReactDOM.render(
                                <div>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <i className="flaticon-edit"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleReplyButton(rowData[9])} disabled={visibility}>Reply</Dropdown.Item>
                                            {ISADMIN ? <Dropdown.Item onClick={() => handleCloseButton(rowData[9])} disabled={visibility}>Set Close</Dropdown.Item>:null}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>,td
                            );
                        }
                    },
                    {
                        targets:[4],
                        className:"center",
                        createdCell:(td,cellData,rowData)=> ReactDOM.render(
                            <div>
                                <button 
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setModal({...modal,isShowModalAttchment:true,data:rowData[4]})
                                    }}
                                >
                                    <i className="flaticon-attachment"></i>
                                </button>
                            </div>,td
                        )
                    }
                ]
            }
        )
    },[])

    const renderImage = (imageData) =>{
        return(
            <div style={{ display:'flex',flexDirection:'column',justifyContent:'center' }}>
                {
                    imageData.map(value=>{
                        return <Image 
                                    key={value.id} 
                                    src={BASE_URI+PREFIX_URI+'image/path/'+value.path+'/'+value.filename}
                                    // style={{ width:600,height:400 }}
                                />   
                    })
                }
            </div>
        )
    }

    const handleCloseButton = () => {
        setModal({
            ...modal,
            data:null,
            isShowModalAttchment:false,
            isShowModalResponse:false
        })
    }

    const handleSubmitReply = async() => {
        const message= textMessage;
        const id= modal.data;
        console.log('payload',id,message);
        try{
            //request new Token
            const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
            let apiCallRequestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));
            // api call
            const url = BASE_URI+PREFIX_URI+ENDPOINT.TICKET_POST_MESSAGE_TICKET;
            let responses = await apiCall(apiCallRequestToken.accessToken,url,'POST',JSON.stringify({'ticket_id':id,username:USERNAME,message:textMessage}));
            if(!responses.success){
                if(responses.message.errors){
                    let errorString = buildValidationError(responses.message.errors);
                    Notification.failed('Failed',errorString,'top-right');
                    return;
                }
                Notification.failed('Failed',responses.message,'top-right');
            }else{
                // setListMessage(responses.data);
                Notification.success('Success',responses.message,'top-right');
                setTextMessage('');
                $(el).DataTable().ajax.reload();
                //request new Token
                const urlToken = BASE_URI+PREFIX_URI+ENDPOINT.TOKEN
                let apiCallRequestToken = await apiCall(TOKEN,urlToken,'POST',JSON.stringify({token:TOKEN}));

                // api call
                const url = BASE_URI+PREFIX_URI+ENDPOINT.TICKET_GET_LIST_MESSAGE_TICKET;
                let requestListMessage = await apiCall(apiCallRequestToken.accessToken,url,'POST',JSON.stringify({'ticket_id':id}));
                if(!requestListMessage.success){
                    if(requestListMessage.message.errors){
                        let errorString = buildValidationError(requestListMessage.message.errors);
                        Notification.failed('Failed',errorString,'top-right');
                        return;
                    }
                    Notification.failed('Failed',requestListMessage.message,'top-right');
                }else{
                    if(typeof requestListMessage.data !== 'undefined'){
                        setListMessage(requestListMessage.data);
                    }
                    Notification.success('Success',requestListMessage.message,'top-right');
                }
            }
        }catch(error){
            console.log(error);
        }
    }

    const renderHistoryChat = () => {
        return(
            <div>
                {modal.isShowModalResponse ? 
                    listMessage.map(value=>{
                        return  <div className="row mt-5" key={value.id}>
                                    <div className="col-md-3">
                                        {value.username}
                                    </div>
                                    <div className="col-md-8" style={{ fontWeight:'bold'}}>
                                        {value.message}
                                        <div style={{ display:'float',color:'blue',fontWeight:'unset'}}>
                                        {format(new Date(value.created_at),'dd MMMM yyyy kk:mm:ss')}
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                    })
                    :
                    null
                }
            </div>
        )
    }

    return(
        <div>
            <table className="table table-bordered table-hover dataTable" id="dataTable" style={{marginTop: '13px !important'}} ref={tableEl}>
                <thead>
                    <tr>
                        <th>Judul Tiket</th>
                        <th>Prioritas</th>
                        <th>Kategori</th>
                        <th>Keterangan</th>
                        <th>Lampiran</th>
                        <th>Status Tiket</th>
                        <th>Balasan Terakhir</th>
                        <th>Dibuat Oleh</th>
                        <th>Tanggal</th>
                        <th>Aksi</th>
                    </tr>
                </thead>
            </table>
            <Modal 
                show={modal.isShowModalAttchment} 
                centered 
                onHide={handleCloseButton}
                size='lg'
                // dialogClassName='modal-90w'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Attachment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    {modal.isShowModalAttchment ?
                        renderImage(JSON.parse(modal.data))
                    : ''

                    }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseButton}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal 
                show={modal.isShowModalResponse} 
                centered 
                onHide={handleCloseButton}
                size='lg'
                // dialogClassName='modal-90w'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {
                            renderHistoryChat(listMessage)
                        }
                        <Row className="mt-4">
                            <Col>
                                <input 
                                    type="text" 
                                    className="form-control"
                                    value={textMessage}
                                    onChange={(e) => setTextMessage(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <button 
                                    className="btn btn-primary"
                                    onClick={handleSubmitReply}
                                >Kirim</button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseButton}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TicketTableFunction;