export const login = (users) => {
    // console.log("Service login dipanggil");
    window.localStorage.setItem("credential", users);
    // console.log("Local storage set item credential");
};

export const isAuthenticated = () => {
    // console.log("Service cek login");
    if (window.localStorage.getItem("credential") == null) {
        // console.log("Belum login");
        // console.log("isi credential", window.localStorage.getItem("credential"));
        return false;
    } else {
        // console.log("sudah login");
        // console.log("isi credential", window.localStorage.getItem("credential"));
        return true;
    }
};

export const logout = () => {
    // console.log("Service Logout dipanggil");
    if (window.localStorage.getItem("credential") != null) {
        window.localStorage.removeItem("credential");
    }
};