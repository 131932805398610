import React from 'react';
// import TicketTable from './TicketTable';
import TicketTable from './TicketTableFunction';

const ListTicketComponent = () => {
    return(
        <div className="container">
            {/*begin::Card*/}
            <div className="card card-custom">
                <div className="card-header">
                    <div className="card-title">
                        <span className="card-icon">
                            <i className="flaticon2-tag text-primary" />
                        </span>
                        <h3 className="card-label">List Ticket</h3>
                    </div>
                </div>
                <div className="card-body">
                    {/*begin: Datatable*/}
                    <TicketTable data=""/>
                    {/* <table className="table table-bordered table-hover table-checkable" id="kt_datatable" style={{marginTop: '13px !important'}}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>email</th>
                                <th>No Tlp</th>
                                <th>Last Login</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                    </table> */}
                    {/*end: Datatable*/}
                </div>
            </div>
            {/*end::Card*/}
        </div>
    )
}

export default ListTicketComponent;