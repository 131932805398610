import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { scriptLoader } from '../../../utils';


const DashboardComponent = (props) =>{
    useEffect(() => {
        scriptLoader();
    },[]);
    
    return(
        <div className="container">
            {/*begin::Dashboard*/}
            {/*begin::Row*/}
            <div className="row">
                <div className="col-xl-4">
                    {/*begin::Mixed Widget 2*/}
                    <div className="card card-custom bg-gray-100 gutter-b card-stretch">
                        {/*begin::Header*/}
                        <div className="card-header border-0 bg-primary py-5">
                            <h3 className="card-title font-weight-bolder text-white">Sales Stat</h3>
                            <div className="card-toolbar">
                                <div className="dropdown dropdown-inline">
                                {/* <a href={"#"} className="btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</a> */}
                                <Link to={"#"} className="btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</Link>
                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                    {/*begin::Navigation*/}
                                    <ul className="navi navi-hover">
                                        <li className="navi-header pb-1">
                                            <span className="text-primary text-uppercase font-weight-bold font-size-sm">Add new:</span>
                                        </li>
                                        <li className="navi-item">
                                            <Link to="#" className="navi-link">
                                                <span className="navi-icon">
                                                    <i className="flaticon2-shopping-cart-1" />
                                                </span>
                                                <span className="navi-text">Order</span>
                                            </Link>
                                        </li>
                                        <li className="navi-item">
                                            <Link to="#" className="navi-link">
                                                <span className="navi-icon">
                                                    <i className="flaticon2-calendar-8" />
                                                </span>
                                                <span className="navi-text">Event</span>
                                            </Link>
                                        </li>
                                        <li className="navi-item">
                                            <Link to="#" className="navi-link">
                                                <span className="navi-icon">
                                                    <i className="flaticon2-graph-1" />
                                                </span>
                                                <span className="navi-text">Report</span>
                                            </Link>
                                        </li>
                                        <li className="navi-item">
                                            <Link to="#" className="navi-link">
                                            <span className="navi-icon">
                                                <i className="flaticon2-rocket-1" />
                                            </span>
                                            <span className="navi-text">Post</span>
                                            </Link>
                                        </li>
                                        <li className="navi-item">
                                            <Link to="#" className="navi-link">
                                            <span className="navi-icon">
                                                <i className="flaticon2-writing" />
                                            </span>
                                            <span className="navi-text">File</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    {/*end::Navigation*/}
                                </div>
                                </div>
                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body p-0 position-relative overflow-hidden">
                            {/*begin::Chart*/}
                            <div id="kt_mixed_widget_2_chart" className="card-rounded-bottom bg-primary" style={{height: 200}} />
                            {/*end::Chart*/}
                            {/*begin::Stats*/}
                            <div className="card-spacer mt-n25">
                                {/*begin::Row*/}
                                <div className="row m-0">
                                    <div className="col bg-white px-6 py-8 rounded-xl mr-7 mb-7">
                                        <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                                            {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Media/Equalizer.svg*/}
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <rect x={0} y={0} width={24} height={24} />
                                                <rect fill="#000000" opacity="0.3" x={13} y={4} width={3} height={16} rx="1.5" />
                                                <rect fill="#000000" x={8} y={9} width={3} height={11} rx="1.5" />
                                                <rect fill="#000000" x={18} y={11} width={3} height={9} rx="1.5" />
                                                <rect fill="#000000" x={3} y={13} width={3} height={7} rx="1.5" />
                                                </g>
                                            </svg>
                                            {/*end::Svg Icon*/}
                                        </span>
                                        <Link to="#" className="text-info font-weight-bold font-size-h6">Weekly Sales</Link>
                                    </div>
                                    <div className="col bg-white px-6 py-8 rounded-xl mb-7">
                                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                            {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Communication/Add-user.svg*/}
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <path d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                <path d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                                                </g>
                                            </svg>
                                            {/*end::Svg Icon*/}
                                        </span>
                                        <Link to="#" className="text-warning font-weight-bold font-size-h6 mt-2">New Users</Link>
                                    </div>
                                </div>
                                {/*end::Row*/}
                                {/*begin::Row*/}
                                <div className="row m-0">
                                    <div className="col bg-white px-6 py-8 rounded-xl mr-7">
                                        <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                                            {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Design/Layers.svg*/}
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fillRule="nonzero" />
                                                <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3" />
                                                </g>
                                            </svg>
                                            {/*end::Svg Icon*/}
                                        </span>
                                        <Link to="#" className="text-danger font-weight-bold font-size-h6 mt-2">Item Orders</Link>
                                    </div>
                                    <div className="col bg-white px-6 py-8 rounded-xl">
                                        <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                            {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Communication/Urgent-mail.svg*/}
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <rect x={0} y={0} width={24} height={24} />
                                                <path d="M12.7037037,14 L15.6666667,10 L13.4444444,10 L13.4444444,6 L9,12 L11.2222222,12 L11.2222222,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L12.7037037,14 Z" fill="#000000" opacity="0.3" />
                                                <path d="M9.80428954,10.9142091 L9,12 L11.2222222,12 L11.2222222,16 L15.6666667,10 L15.4615385,10 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 L9.80428954,10.9142091 Z" fill="#000000" />
                                                </g>
                                            </svg>
                                            {/*end::Svg Icon*/}
                                        </span>
                                        <Link to="#" className="text-success font-weight-bold font-size-h6 mt-2">Bug Reports</Link>
                                    </div>
                                </div>
                                {/*end::Row*/}
                            </div>
                            {/*end::Stats*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                    {/*end::Mixed Widget 2*/}
                </div>
                <div className="col-xl-4">
                    {/*begin::Mixed Widget 18*/}
                    <div className="card card-custom gutter-b card-stretch">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-5">
                            <div className="card-title font-weight-bolder">
                                <div className="card-label">Weekly Sales Stats
                                <div className="font-size-sm text-muted mt-2">890,344 Sales</div></div>
                            </div>
                            <div className="card-toolbar">
                                <div className="dropdown dropdown-inline">
                                    <Link to="#" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="ki ki-bold-more-hor" />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                        {/*begin::Navigation*/}
                                        <ul className="navi navi-hover">
                                            <li className="navi-header font-weight-bold py-4">
                                                <span className="font-size-lg">Choose Label:</span>
                                                <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..." />
                                            </li>
                                            <li className="navi-separator mb-3 opacity-70" />
                                            <li className="navi-item">
                                                <Link to="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-success">Customer</span>
                                                </span>
                                                </Link>
                                            </li>
                                            <li className="navi-item">
                                                <Link to="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-danger">Partner</span>
                                                </span>
                                                </Link>
                                            </li>
                                            <li className="navi-item">
                                                <Link to="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-warning">Suplier</span>
                                                </span>
                                                </Link>
                                            </li>
                                            <li className="navi-item">
                                                <Link to="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-primary">Member</span>
                                                </span>
                                                </Link>
                                            </li>
                                            <li className="navi-item">
                                                <Link to="#" className="navi-link">
                                                <span className="navi-text">
                                                    <span className="label label-xl label-inline label-light-dark">Staff</span>
                                                </span>
                                                </Link>
                                            </li>
                                            <li className="navi-separator mt-3 opacity-70" />
                                            <li className="navi-footer py-4">
                                                <Link className="btn btn-clean font-weight-bold btn-sm" to="#">
                                                    <i className="ki ki-plus icon-sm" />
                                                    Add new
                                                </Link>
                                            </li>
                                        </ul>
                                        {/*end::Navigation*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body">
                            {/*begin::Chart*/}
                            <div id="kt_mixed_widget_18_chart" style={{height: 250}} />
                            {/*end::Chart*/}
                            {/*begin::Items*/}
                            <div className="mt-n12 position-relative zindex-0">
                                {/*begin::Widget Item*/}
                                <div className="d-flex align-items-center mb-8">
                                    {/*begin::Symbol*/}
                                    <div className="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                        <div className="symbol-label">
                                            <span className="svg-icon svg-icon-lg svg-icon-gray-500">
                                                {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Media/Equalizer.svg*/}
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <rect x={0} y={0} width={24} height={24} />
                                                        <rect fill="#000000" opacity="0.3" x={13} y={4} width={3} height={16} rx="1.5" />
                                                        <rect fill="#000000" x={8} y={9} width={3} height={11} rx="1.5" />
                                                        <rect fill="#000000" x={18} y={11} width={3} height={9} rx="1.5" />
                                                        <rect fill="#000000" x={3} y={13} width={3} height={7} rx="1.5" />
                                                    </g>
                                                </svg>
                                                {/*end::Svg Icon*/}
                                            </span>
                                        </div>
                                    </div>
                                    {/*end::Symbol*/}
                                    {/*begin::Title*/}
                                    <div>
                                        <Link to="#" className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Most Sales</Link>
                                        <div className="font-size-sm text-muted font-weight-bold mt-1">Authors with the best sales</div>
                                    </div>
                                    {/*end::Title*/}
                                </div>
                                {/*end::Widget Item*/}
                                {/*begin::Widget Item*/}
                                <div className="d-flex align-items-center mb-8">
                                    {/*begin::Symbol*/}
                                    <div className="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                        <div className="symbol-label">
                                            <span className="svg-icon svg-icon-lg svg-icon-gray-500">
                                                {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Shopping/Chart-pie.svg*/}
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <rect x={0} y={0} width={24} height={24} />
                                                        <path d="M4.00246329,12.2004927 L13,14 L13,4.06189375 C16.9463116,4.55399184 20,7.92038235 20,12 C20,16.418278 16.418278,20 12,20 C7.64874861,20 4.10886412,16.5261253 4.00246329,12.2004927 Z" fill="#000000" opacity="0.3" />
                                                        <path d="M3.0603968,10.0120794 C3.54712466,6.05992157 6.91622084,3 11,3 L11,11.6 L3.0603968,10.0120794 Z" fill="#000000" />
                                                    </g>
                                                </svg>
                                                {/*end::Svg Icon*/}
                                            </span>
                                        </div>
                                    </div>
                                    {/*end::Symbol*/}
                                    {/*begin::Title*/}
                                    <div>
                                        <Link to="#" className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Total Sales Lead</Link>
                                        <div className="font-size-sm text-muted font-weight-bold mt-1">40% increased on week-to-week reports</div>
                                    </div>
                                    {/*end::Title*/}
                                </div>
                                {/*end::Widget Item*/}
                                {/*begin::Widget Item*/}
                                <div className="d-flex align-items-center">
                                    {/*begin::Symbol*/}
                                    <div className="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                        <div className="symbol-label">
                                            <span className="svg-icon svg-icon-lg svg-icon-gray-500">
                                                {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Design/Layers.svg*/}
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                        <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fillRule="nonzero" />
                                                        <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3" />
                                                    </g>
                                                </svg>
                                                {/*end::Svg Icon*/}
                                            </span>
                                        </div>
                                    </div>
                                    {/*end::Symbol*/}
                                    {/*begin::Title*/}
                                    <div>
                                        <Link to="#" className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Average Bestseller</Link>
                                        <div className="font-size-sm text-muted font-weight-bold mt-1">Pitstop Email Marketing</div>
                                    </div>
                                    {/*end::Title*/}
                                </div>
                                {/*end::Widget Item*/}
                            </div>
                            {/*end::Items*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                    {/*end::Mixed Widget 18*/}
                </div>
                <div className="col-xl-4">
                {/*begin::List Widget 7*/}
                <div className="card card-custom gutter-b card-stretch">
                    {/*begin::Header*/}
                    <div className="card-header border-0">
                        <h3 className="card-title font-weight-bolder text-dark">Trends</h3>
                        <div className="card-toolbar">
                            <div className="dropdown dropdown-inline">
                                <Link to="#" className="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="ki ki-bold-more-ver" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                    {/*begin::Naviigation*/}
                                    <ul className="navi">
                                        <li className="navi-header font-weight-bold py-5">
                                            <span className="font-size-lg">Add New:</span>
                                            <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..." />
                                        </li>
                                        <li className="navi-separator mb-3 opacity-70" />
                                        <li className="navi-item">
                                            <Link to="#" className="navi-link">
                                            <span className="navi-icon">
                                                <i className="flaticon2-shopping-cart-1" />
                                            </span>
                                            <span className="navi-text">Order</span>
                                            </Link>
                                        </li>
                                        <li className="navi-item">
                                            <Link to="#" className="navi-link">
                                            <span className="navi-icon">
                                                <i className="navi-icon flaticon2-calendar-8" />
                                            </span>
                                            <span className="navi-text">Members</span>
                                            <span className="navi-label">
                                                <span className="label label-light-danger label-rounded font-weight-bold">3</span>
                                            </span>
                                            </Link>
                                        </li>
                                        <li className="navi-item">
                                            <Link to="#" className="navi-link">
                                            <span className="navi-icon">
                                                <i className="navi-icon flaticon2-telegram-logo" />
                                            </span>
                                            <span className="navi-text">Project</span>
                                            </Link>
                                        </li>
                                        <li className="navi-item">
                                            <Link to="#" className="navi-link">
                                            <span className="navi-icon">
                                                <i className="navi-icon flaticon2-new-email" />
                                            </span>
                                            <span className="navi-text">Record</span>
                                            <span className="navi-label">
                                                <span className="label label-light-success label-rounded font-weight-bold">5</span>
                                            </span>
                                            </Link>
                                        </li>
                                        <li className="navi-separator mt-3 opacity-70" />
                                        <li className="navi-footer pt-5 pb-4">
                                            <Link className="btn btn-light-primary font-weight-bolder btn-sm" to="#">More options</Link>
                                            <Link className="btn btn-clean font-weight-bold btn-sm d-none" to="#" data-toggle="tooltip" data-placement="right" title="Click to learn more...">Learn more</Link>
                                        </li>
                                    </ul>
                                    {/*end::Naviigation*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-0">
                        {/*begin::Item*/}
                        <div className="d-flex align-items-center flex-wrap mb-10">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-50 symbol-light mr-5">
                                <span className="symbol-label">
                                    <img alt="avatar" src={process.env.PUBLIC_URL+ "dist/assets/media/svg/misc/006-plurk.svg"} className="h-50 align-self-center"/>
                                </span>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Text*/}
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <Link to="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Top Authors</Link>
                                <span className="text-muted font-weight-bold">Mark, Rowling, Esther</span>
                            </div>
                            {/*end::Text*/}
                            <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">+82$</span>
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="d-flex align-items-center flex-wrap mb-10">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-50 symbol-light mr-5">
                                <span className="symbol-label">
                                    <img alt="avatar" src={process.env.PUBLIC_URL+ "dist/assets/media/svg/misc/015-telegram.svg"} className="h-50 align-self-center"/>
                                </span>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Text*/}
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <Link to="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Popular Authors</Link>
                                <span className="text-muted font-weight-bold">Randy, Steve, Mike</span>
                            </div>
                            {/*end::Text*/}
                            <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">+280$</span>
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="d-flex align-items-center flex-wrap mb-10">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-50 symbol-light mr-5">
                                <span className="symbol-label">
                                    <img alt="avatar" src={process.env.PUBLIC_URL+ "dist/assets/media/svg/misc/003-puzzle.svg"} className="h-50 align-self-center"/>
                                </span>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Text*/}
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <Link to="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">New Users</Link>
                                <span className="text-muted font-weight-bold">John, Pat, Jimmy</span>
                            </div>
                            {/*end::Text*/}
                            <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">+4500$</span>
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="d-flex align-items-center flex-wrap mb-10">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-50 symbol-light mr-5">
                                <span className="symbol-label">
                                    <img alt="avatar" src={process.env.PUBLIC_URL+ "dist/assets/media/svg/misc/005-bebo.svg"} className="h-50 align-self-center"/>
                                </span>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Text*/}
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <Link to="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Active Customers</Link>
                                <span className="text-muted font-weight-bold">Sandra, Tim, Louis</span>
                            </div>
                            {/*end::Text*/}
                            <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">+4500$</span>
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="d-flex align-items-center flex-wrap">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-50 symbol-light mr-5">
                                <span className="symbol-label">
                                    <img alt="avatar" src={process.env.PUBLIC_URL+ "dist/assets/media/svg/misc/014-kickstarter.svg"} className="h-50 align-self-center"/>
                                </span>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Text*/}
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                <Link to="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Bestseller Theme</Link>
                                <span className="text-muted font-weight-bold">Disco, Retro, Sports</span>
                            </div>
                            {/*end::Text*/}
                            <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">+4500$</span>
                        </div>
                        {/*end::Item*/}
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::List Widget 7*/}
                </div>
            </div>
            {/*end::Row*/}
            {/*begin::Row*/}
            <div className="row">
                <div className="col-xl-4">
                    {/*begin::Stats Widget 1*/}
                    <div className="card card-custom bgi-no-repeat gutter-b card-stretch" style={{backgroundPosition: 'right top', backgroundSize: '30% auto', backgroundImage: 'url(./dist/assets/media/svg/shapes/abstract-4.svg)'}}>
                        {/*begin::Body*/}
                        <div className="card-body">
                            <Link to="#" className="card-title font-weight-bold text-muted text-hover-primary font-size-h5">Meeting Schedule</Link>
                            <div className="font-weight-bold text-success mt-9 mb-5">3:30PM - 4:20PM</div>
                            <p className="text-dark-75 font-weight-bolder font-size-h5 m-0">Craft a headline that is informative
                            <br />and will capture readers</p>
                        </div>
                        {/*end::Body*/}
                    </div>
                    {/*end::Stats Widget 1*/}
                    </div>
                    <div className="col-xl-4">
                        {/*begin::Stats Widget 2*/}
                        <div className="card card-custom bgi-no-repeat gutter-b card-stretch" style={{backgroundPosition: 'right top', backgroundSize: '30% auto', backgroundImage: 'url(./dist/assets/media/svg/shapes/abstract-2.svg)'}}>
                            {/*begin::Body*/}
                            <div className="card-body">
                            <Link to="#" className="card-title font-weight-bold text-muted text-hover-primary font-size-h5">Announcement</Link>
                            <div className="font-weight-bold text-success mt-9 mb-5">03 May 2020</div>
                            <p className="text-dark-75 font-weight-bolder font-size-h5 m-0">Great blog posts don’t just happen
                                <br />Even the best bloggers need it</p>
                            </div>
                            {/*end::Body*/}
                        </div>
                        {/*end::Stats Widget 2*/}
                    </div>
                    <div className="col-xl-4">
                        {/*begin::Stats Widget 3*/}
                        <div className="card card-custom bgi-no-repeat gutter-b card-stretch" style={{backgroundPosition: 'right top', backgroundSize: '30% auto', backgroundImage: 'url(./dist/assets/media/svg/shapes/abstract-1.svg)'}}>
                            {/*begin::body*/}
                            <div className="card-body">
                            <Link to="#" className="card-title font-weight-bold text-muted text-hover-primary font-size-h5">New Release</Link>
                            <div className="font-weight-bold text-success mt-9 mb-5">ReactJS</div>
                            <p className="text-dark-75 font-weight-bolder font-size-h5 m-0">AirWays - A Front-end solution for
                                <br />airlines build with ReactJS</p>
                            </div>
                            {/*end::Body*/}
                        </div>
                        {/*end::Stats Widget 3*/}
                </div>
            </div>
            {/*end::Row*/}
            {/*begin::Row*/}
            <div className="row">
                <div className="col-xl-6">
                    {/*begin::Base Table Widget 3*/}
                    <div className="card card-custom gutter-b card-stretch">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">Files</span>
                                <span className="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>
                            </h3>
                            <div className="card-toolbar">
                                <Link to="#" className="btn btn-light-primary btn-md py-2 font-weight-bolder dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Create</Link>
                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                    {/*begin::Navigation*/}
                                    <ul className="navi navi-hover">
                                        <li className="navi-header pb-1">
                                        <span className="text-primary text-uppercase font-weight-bold font-size-sm">Add new:</span>
                                        </li>
                                        <li className="navi-item">
                                        <Link to="#" className="navi-link">
                                            <span className="navi-icon">
                                            <i className="flaticon2-shopping-cart-1" />
                                            </span>
                                            <span className="navi-text">Order</span>
                                        </Link>
                                        </li>
                                        <li className="navi-item">
                                        <Link to="#" className="navi-link">
                                            <span className="navi-icon">
                                            <i className="flaticon2-calendar-8" />
                                            </span>
                                            <span className="navi-text">Event</span>
                                        </Link>
                                        </li>
                                        <li className="navi-item">
                                        <Link to="#" className="navi-link">
                                            <span className="navi-icon">
                                            <i className="flaticon2-graph-1" />
                                            </span>
                                            <span className="navi-text">Report</span>
                                        </Link>
                                        </li>
                                        <li className="navi-item">
                                        <Link to="#" className="navi-link">
                                            <span className="navi-icon">
                                            <i className="flaticon2-rocket-1" />
                                            </span>
                                            <span className="navi-text">Post</span>
                                        </Link>
                                        </li>
                                        <li className="navi-item">
                                        <Link to="#" className="navi-link">
                                            <span className="navi-icon">
                                            <i className="flaticon2-writing" />
                                            </span>
                                            <span className="navi-text">File</span>
                                        </Link>
                                        </li>
                                    </ul>
                                    {/*end::Navigation*/}
                                </div>
                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body pt-2 pb-0">
                            {/*begin::Table*/}
                            <div className="table-responsive">
                                <table className="table table-borderless table-vertical-center">
                                    <thead>
                                        <tr>
                                            <th className="p-0" style={{width: 50}} />
                                            <th className="p-0" style={{minWidth: 150}} />
                                            <th className="p-0" style={{minWidth: 140}} />
                                            <th className="p-0" style={{minWidth: 100}} />
                                            <th className="p-0" style={{minWidth: 40}} />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="pl-0 py-5">
                                                <div className="symbol symbol-45 symbol-light-success mr-2">
                                                    <span className="symbol-label">
                                                        <span className="svg-icon svg-icon-2x svg-icon-success">
                                                            {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Shopping/Cart3.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                <rect x={0} y={0} width={24} height={24} />
                                                                <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                <path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="pl-0">
                                                <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Top Authors</Link>
                                            </td>
                                            <td className="text-right">
                                                <span className="text-muted font-weight-bold">ReactJs, HTML</span>
                                            </td>
                                            <td className="text-right">
                                                <span className="text-muted font-weight-bold">4600 Users</span>
                                            </td>
                                            <td className="text-right pr-0">
                                                <span className="text-dark-75 font-weight-bolder font-size-lg">5.4MB</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pl-0 py-5">
                                                <div className="symbol symbol-45 symbol-light-danger mr-2">
                                                    <span className="symbol-label">
                                                        <span className="svg-icon svg-icon-2x svg-icon-danger">
                                                            {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Layout/Layout-4-blocks.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                <rect x={0} y={0} width={24} height={24} />
                                                                <rect fill="#000000" x={4} y={4} width={7} height={7} rx="1.5" />
                                                                <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="pl-0">
                                                <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Popular Authors</Link>
                                            </td>
                                            <td className="text-right">
                                                <span className="text-muted font-weight-bold">Python, MySQL</span>
                                            </td>
                                            <td className="text-right">
                                                <span className="text-muted font-weight-bold">7200 Users</span>
                                            </td>
                                            <td className="text-right pr-0">
                                                <span className="text-dark-75 font-weight-bolder font-size-lg">2.8MB</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pl-0 py-5">
                                                <div className="symbol symbol-45 symbol-light-info mr-2">
                                                    <span className="symbol-label">
                                                        <span className="svg-icon svg-icon-2x svg-icon-info">
                                                            {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Communication/Group.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                                <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="pl-0">
                                                <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">New Users</Link>
                                            </td>
                                            <td className="text-right">
                                                <span className="text-muted font-weight-bold">Laravel, Metronic</span>
                                            </td>
                                            <td className="text-right">
                                                <span className="text-muted font-weight-bold">890 Users</span>
                                            </td>
                                            <td className="text-right pr-0">
                                                <span className="text-dark-75 font-weight-bolder font-size-lg">1.5MB</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pl-0 py-5">
                                                <div className="symbol symbol-45 symbol-light-warning mr-2">
                                                    <span className="symbol-label">
                                                        <span className="svg-icon svg-icon-2x svg-icon-warning">
                                                            {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Home/Library.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                <rect x={0} y={0} width={24} height={24} />
                                                                <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000" />
                                                                <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width={3} height={18} rx={1} />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="pl-0">
                                                <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Active Customers</Link>
                                            </td>
                                            <td className="text-right">
                                                <span className="text-muted font-weight-bold">AngularJS, C#</span>
                                            </td>
                                            <td className="text-right">
                                                <span className="text-muted font-weight-bold">6370 Users</span>
                                            </td>
                                            <td className="text-right pr-0">
                                                <span className="text-dark-75 font-weight-bolder font-size-lg">890KB</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pl-0 py-5">
                                                <div className="symbol symbol-45 symbol-light-primary mr-2">
                                                    <span className="symbol-label">
                                                        <span className="svg-icon svg-icon-2x svg-icon-primary">
                                                            {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Shopping/Box2.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                <rect x={0} y={0} width={24} height={24} />
                                                                <path d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z" fill="#000000" />
                                                                <path d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z" fill="#000000" opacity="0.3" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="pl-0">
                                                <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Bestseller Theme</Link>
                                            </td>
                                            <td className="text-right">
                                                <span className="text-muted font-weight-bold">ReactJS, Ruby</span>
                                            </td>
                                            <td className="text-right">
                                                <span className="text-muted font-weight-bold">354 Users</span>
                                            </td>
                                            <td className="text-right pr-0">
                                                <span className="text-dark-75 font-weight-bolder font-size-lg">500KB</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/*end::table*/}
                        </div>
                        {/*begin::Body*/}
                    </div>
                    {/*end::Base Table Widget 3*/}
                </div>
                <div className="col-xl-6">
                {/*begin::Base Table Widget 5*/}
                <div className="card card-custom gutter-b card-stretch">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">New Arrivals</span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>
                    </h3>
                    <div className="card-toolbar">
                        <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                        <li className="nav-item">
                            <a className="nav-link py-2 px-4" data-toggle="tab" href="#kt_tab_pane_5_1">Month</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link py-2 px-4" data-toggle="tab" href="#kt_tab_pane_5_2">Week</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link py-2 px-4 active" data-toggle="tab" href="#kt_tab_pane_5_3">Day</a>
                        </li>
                        </ul>
                    </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                    <div className="tab-content mt-5" id="myTabTables5">
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="kt_tab_pane_5_1" role="tabpanel" aria-labelledby="kt_tab_pane_5_1">
                        {/*begin::Table*/}
                        <div className="table-responsive">
                            <table className="table table-borderless table-vertical-center">
                            <thead>
                                <tr>
                                <th className="p-0 w-50px" />
                                <th className="p-0 min-w-150px" />
                                <th className="p-0 min-w-140px" />
                                <th className="p-0 min-w-110px" />
                                <th className="p-0 min-w-50px" />
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "dist/assets/media/svg/misc/015-telegram.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Popular Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Most Successful</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">Python, MySQL</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-warning label-inline">In Progress</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "dist/assets/media/svg/misc/003-puzzle.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">New Users</Link>
                                    <span className="text-muted font-weight-bold d-block">Awesome UsersSS</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">Laravel,Metronic</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-success label-inline">Success</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/005-bebo.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Active Customers</Link>
                                    <span className="text-muted font-weight-bold d-block">Best Customers</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">AngularJS, C#</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-danger label-inline">Rejected</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/006-plurk.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Top Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Successful Fellas</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">ReactJs, HTML</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-primary label-inline">Approved</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/014-kickstarter.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Bestseller Theme</Link>
                                    <span className="text-muted font-weight-bold d-block">Amazing Templates</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">ReactJS, Ruby</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-warning label-inline">In Progress</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        {/*end::Table*/}
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="kt_tab_pane_5_2" role="tabpanel" aria-labelledby="kt_tab_pane_5_2">
                        {/*begin::Table*/}
                        <div className="table-responsive">
                            <table className="table table-borderless table-vertical-center">
                            <thead>
                                <tr>
                                <th className="p-0 w-50px" />
                                <th className="p-0 min-w-150px" />
                                <th className="p-0 min-w-140px" />
                                <th className="p-0 min-w-110px" />
                                <th className="p-0 min-w-50px" />
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/005-bebo.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Active Customers</Link>
                                    <span className="text-muted font-weight-bold d-block">Best Customers</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">AngularJS, C#</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-danger label-inline">Rejected</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/014-kickstarter.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Bestseller Theme</Link>
                                    <span className="text-muted font-weight-bold d-block">Amazing Templates</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">ReactJS, Ruby</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-warning label-inline">In Progress</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/006-plurk.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Top Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Successful Fellas</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">ReactJs, HTML</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-primary label-inline">Approved</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/015-telegram.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Popular Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Most Successful</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">Python, MySQL</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-warning label-inline">In Progress</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/003-puzzle.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">New Users</Link>
                                    <span className="text-muted font-weight-bold d-block">Awesome Users</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">Laravel,Metronic</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-success label-inline">Success</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        {/*end::Table*/}
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade show active" id="kt_tab_pane_5_3" role="tabpanel" aria-labelledby="kt_tab_pane_5_3">
                        {/*begin::Table*/}
                        <div className="table-responsive">
                            <table className="table table-borderless table-vertical-center">
                            <thead>
                                <tr>
                                <th className="p-0 w-50px" />
                                <th className="p-0 min-w-150px" />
                                <th className="p-0 min-w-140px" />
                                <th className="p-0 min-w-110px" />
                                <th className="p-0 min-w-50px" />
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/006-plurk.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Top Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Successful Fellas</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">ReactJs, HTML</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-primary label-inline">Approved</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/015-telegram.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Popular Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Most Successful</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">Python, MySQL</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-warning label-inline">In Progress</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/003-puzzle.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">New Users</Link>
                                    <span className="text-muted font-weight-bold d-block">Awesome Users</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">Laravel,Metronic</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-success label-inline">Success</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/005-bebo.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Active Customers</Link>
                                    <span className="text-muted font-weight-bold d-block">Best Customers</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">AngularJS, C#</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-danger label-inline">Rejected</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="py-5 pl-0">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/014-kickstarter.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Bestseller Theme</Link>
                                    <span className="text-muted font-weight-bold d-block">Amazing Templates</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-500">ReactJS, Ruby</span>
                                </td>
                                <td className="text-right">
                                    <span className="label label-lg label-light-warning label-inline">In Progress</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        {/*end::Table*/}
                        </div>
                        {/*end::Tap pane*/}
                    </div>
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::Base Table Widget 5*/}
                </div>
            </div>
            {/*end::Row*/}
            {/*begin::Row*/}
            <div className="row">
                <div className="col-xl-4">
                {/*begin::Mixed Widget 10*/}
                <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Body*/}
                    <div className="card-body d-flex flex-column">
                    <div className="flex-grow-1 pb-5">
                        {/*begin::Info*/}
                        <div className="d-flex align-items-center pr-2 mb-6">
                        <span className="text-muted font-weight-bold font-size-lg flex-grow-1">7 Hours Ago</span>
                        <div className="symbol symbol-50">
                            <span className="symbol-label bg-light-light">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/006-plurk.svg"} className="h-50 align-self-center"/>
                            </span>
                        </div>
                        </div>
                        {/*end::Info*/}
                        {/*begin::Link*/}
                        <Link to="#" className="text-dark font-weight-bolder text-hover-primary font-size-h4">PitStop - Multiple Email
                        <br />Generator</Link>
                        {/*end::Link*/}
                        {/*begin::Desc*/}
                        <p className="text-dark-50 font-weight-normal font-size-lg mt-6">Pitstop creates quick email campaigns.
                        <br />We help to strengthen your brand
                        <br />for your every purpose.</p>
                        {/*end::Desc*/}
                    </div>
                    {/*begin::Team*/}
                    <div className="d-flex align-items-center">
                        {/*begin::Pic*/}
                        <Link to="#" className="symbol symbol-45 symbol-light mr-3">
                        <div className="symbol-label">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/avatars/001-boy.svg" }className="h-75 align-self-end"/>
                        </div>
                        </Link>
                        {/*end::Pic*/}
                        {/*begin::Pic*/}
                        <Link to="#" className="symbol symbol-45 symbol-light mr-3">
                        <div className="symbol-label">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/avatars/028-girl-16.svg"} className="h-75 align-self-end"/>
                        </div>
                        </Link>
                        {/*end::Pic*/}
                        {/*begin::Pic*/}
                        <Link to="#" className="symbol symbol-45 symbol-light">
                        <div className="symbol-label">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/avatars/024-boy-9.svg"} className="h-75 align-self-end"/>
                        </div>
                        </Link>
                        {/*end::Pic*/}
                    </div>
                    {/*end::Team*/}
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::Mixed Widget 10*/}
                </div>
                <div className="col-xl-4">
                {/*begin::Mixed Widget 11*/}
                <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Body*/}
                    <div className="card-body d-flex flex-column">
                    <div className="flex-grow-1 pb-5">
                        {/*begin::Info*/}
                        <div className="d-flex align-items-center pr-2 mb-6">
                        <span className="text-muted font-weight-bold font-size-lg flex-grow-1">2 Days Ago</span>
                        <div className="symbol symbol-50">
                            <span className="symbol-label bg-light-light">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/015-telegram.svg"} className="h-50 align-self-center"/>
                            </span>
                        </div>
                        </div>
                        {/*end::Info*/}
                        <Link to="#" className="text-dark font-weight-bolder text-hover-primary font-size-h4">Craft - ReactJS Admin
                        <br />Theme</Link>
                        {/*begin::Desc*/}
                        <p className="text-dark-50 font-weight-normal font-size-lg mt-6">Craft uses the latest and greatest frameworks
                        <br />with ReactJS for complete modernization and
                        <br />future proofing your business operations
                        <br />and sales opportunities</p>
                        {/*end::Desc*/}
                    </div>
                    {/*begin::Team*/}
                    <div className="d-flex align-items-center">
                        {/*begin::Pic*/}
                        <Link to="#" className="symbol symbol-45 symbol-light mr-3">
                        <div className="symbol-label">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/avatars/001-boy.svg"} className="h-75 align-self-end"/>
                        </div>
                        </Link>
                        {/*end::Pic*/}
                        {/*begin::Pic*/}
                        <Link to="#" className="symbol symbol-45 symbol-light mr-3">
                        <div className="symbol-label">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/avatars/028-girl-16.svg"} className="h-75 align-self-end"/>
                        </div>
                        </Link>
                        {/*end::Pic*/}
                        {/*begin: Pic*/}
                        <Link to="#" className="symbol symbol-45 symbol-light mr-3">
                        <div className="symbol-label">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/avatars/024-boy-9.svg"} className="h-75 align-self-end"/>
                        </div>
                        </Link>
                        {/*end::Pic*/}
                        {/*begin::Pic*/}
                        <Link to="#" className="symbol symbol-45 symbol-light">
                        <div className="symbol-label">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/avatars/005-girl-2.svg"} className="h-75 align-self-end"/>
                        </div>
                        </Link>
                        {/*end::Pic*/}
                    </div>
                    {/*end::Team*/}
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::Mixed Widget 11*/}
                </div>
                <div className="col-xl-4">
                {/*begin::Mixed Widget 12*/}
                <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Body*/}
                    <div className="card-body d-flex flex-column">
                    <div className="flex-grow-1 pb-5">
                        {/*begin::Info*/}
                        <div className="d-flex align-items-center pr-2 mb-6">
                        <span className="text-muted font-weight-bold font-size-lg flex-grow-1">5 Weeks Ago</span>
                        <div className="symbol symbol-50">
                            <span className="symbol-label bg-light-light">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/003-puzzle.svg"} className="h-50 align-self-center"/>
                            </span>
                        </div>
                        </div>
                        {/*end::Info*/}
                        <Link to="#" className="text-dark font-weight-bolder text-hover-primary font-size-h4">KT.com - High Quality
                        <br />Templates</Link>
                        {/*begin::Desc*/}
                        <p className="text-dark-50 font-weight-normal font-size-lg mt-6">Easy to use, incredibly flexible and secure
                        <br />with in-depth documentation that outlines
                        <br />everything for you</p>
                        {/*end::Desc*/}
                    </div>
                    {/*begin::Team*/}
                    <div className="d-flex align-items-center">
                        {/*begin::Pic*/}
                        <Link to="#" className="symbol symbol-45 symbol-light mr-3">
                        <div className="symbol-label">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/avatars/001-boy.svg"} className="h-75 align-self-end"/>
                        </div>
                        </Link>
                        {/*end::Pic*/}
                        {/*begin::Pic*/}
                        <Link to="#" className="symbol symbol-45 symbol-light mr-3">
                        <div className="symbol-label">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/avatars/028-girl-16.svg"} className="h-75 align-self-end"/>
                        </div>
                        </Link>
                        {/*end::Pic*/}
                        {/*begin::Pic*/}
                        <Link to="#" className="symbol symbol-45 symbol-light">
                        <div className="symbol-label">
                            <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/avatars/024-boy-9.svg"} className="h-75 align-self-end"/>
                        </div>
                        </Link>
                        {/*end::Pic*/}
                    </div>
                    {/*end::Team*/}
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::Mixed Widget 12*/}
                </div>
            </div>
            {/*end::Row*/}
            {/*begin::Row*/}
            <div className="row">
                <div className="col-lg-4">
                {/*begin::Mixed Widget 16*/}
                <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                    <div className="card-title">
                        <div className="card-label">
                        <div className="font-weight-bolder">Weekly Sales Stats</div>
                        <div className="font-size-sm text-muted mt-2">890,344 Sales</div>
                        </div>
                    </div>
                    <div className="card-toolbar">
                        <div className="dropdown dropdown-inline">
                        <Link to="#" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="ki ki-bold-more-hor" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                            {/*begin::Navigation*/}
                            <ul className="navi navi-hover">
                            <li className="navi-header font-weight-bold py-4">
                                <span className="font-size-lg">Choose Label:</span>
                                <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..." />
                            </li>
                            <li className="navi-separator mb-3 opacity-70" />
                            <li className="navi-item">
                                <Link to="#" className="navi-link">
                                <span className="navi-text">
                                    <span className="label label-xl label-inline label-light-success">Customer</span>
                                </span>
                                </Link>
                            </li>
                            <li className="navi-item">
                                <Link to="#" className="navi-link">
                                <span className="navi-text">
                                    <span className="label label-xl label-inline label-light-danger">Partner</span>
                                </span>
                                </Link>
                            </li>
                            <li className="navi-item">
                                <Link to="#" className="navi-link">
                                <span className="navi-text">
                                    <span className="label label-xl label-inline label-light-warning">Suplier</span>
                                </span>
                                </Link>
                            </li>
                            <li className="navi-item">
                                <Link to="#" className="navi-link">
                                <span className="navi-text">
                                    <span className="label label-xl label-inline label-light-primary">Member</span>
                                </span>
                                </Link>
                            </li>
                            <li className="navi-item">
                                <Link to="#" className="navi-link">
                                <span className="navi-text">
                                    <span className="label label-xl label-inline label-light-dark">Staff</span>
                                </span>
                                </Link>
                            </li>
                            <li className="navi-separator mt-3 opacity-70" />
                            <li className="navi-footer py-4">
                                <Link className="btn btn-clean font-weight-bold btn-sm" to="#">
                                <i className="ki ki-plus icon-sm" />Add new</Link>
                            </li>
                            </ul>
                            {/*end::Navigation*/}
                        </div>
                        </div>
                    </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body d-flex flex-column">
                    {/*begin::Chart*/}
                    <div className="flex-grow-1">
                        <div id="kt_mixed_widget_16_chart" style={{height: 200}} />
                    </div>
                    {/*end::Chart*/}
                    {/*begin::Items*/}
                    <div className="mt-10 mb-5">
                        <div className="row row-paddingless mb-10">
                        {/*begin::Item*/}
                        <div className="col">
                            <div className="d-flex align-items-center mr-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-45 symbol-light-info mr-4 flex-shrink-0">
                                <div className="symbol-label">
                                <span className="svg-icon svg-icon-lg svg-icon-info">
                                    {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Shopping/Cart3.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000" />
                                    </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                </span>
                                </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                                <div className="font-size-h4 text-dark-75 font-weight-bolder">$2,034</div>
                                <div className="font-size-sm text-muted font-weight-bold mt-1">Author Sales</div>
                            </div>
                            {/*end::Title*/}
                            </div>
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="col">
                            <div className="d-flex align-items-center mr-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
                                <div className="symbol-label">
                                <span className="svg-icon svg-icon-lg svg-icon-danger">
                                    {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Home/Library.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000" />
                                        <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width={3} height={18} rx={1} />
                                    </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                </span>
                                </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                                <div className="font-size-h4 text-dark-75 font-weight-bolder">$706</div>
                                <div className="font-size-sm text-muted font-weight-bold mt-1">Commission</div>
                            </div>
                            {/*end::Title*/}
                            </div>
                        </div>
                        {/*end::Item*/}
                        </div>
                        <div className="row row-paddingless">
                        {/*begin::Item*/}
                        <div className="col">
                            <div className="d-flex align-items-center mr-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">
                                <div className="symbol-label">
                                <span className="svg-icon svg-icon-lg svg-icon-success">
                                    {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Shopping/Cart3.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000" />
                                    </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                </span>
                                </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                                <div className="font-size-h4 text-dark-75 font-weight-bolder">$49</div>
                                <div className="font-size-sm text-muted font-weight-bold mt-1">Average Bid</div>
                            </div>
                            {/*end::Title*/}
                            </div>
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="col">
                            <div className="d-flex align-items-center mr-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-45 symbol-light-primary mr-4 flex-shrink-0">
                                <div className="symbol-label">
                                <span className="svg-icon svg-icon-lg svg-icon-primary">
                                    {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Shopping/Barcode-read.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <rect fill="#000000" opacity="0.3" x={4} y={4} width={8} height={16} />
                                        <path d="M6,18 L9,18 C9.66666667,18.1143819 10,18.4477153 10,19 C10,19.5522847 9.66666667,19.8856181 9,20 L4,20 L4,15 C4,14.3333333 4.33333333,14 5,14 C5.66666667,14 6,14.3333333 6,15 L6,18 Z M18,18 L18,15 C18.1143819,14.3333333 18.4477153,14 19,14 C19.5522847,14 19.8856181,14.3333333 20,15 L20,20 L15,20 C14.3333333,20 14,19.6666667 14,19 C14,18.3333333 14.3333333,18 15,18 L18,18 Z M18,6 L15,6 C14.3333333,5.88561808 14,5.55228475 14,5 C14,4.44771525 14.3333333,4.11438192 15,4 L20,4 L20,9 C20,9.66666667 19.6666667,10 19,10 C18.3333333,10 18,9.66666667 18,9 L18,6 Z M6,6 L6,9 C5.88561808,9.66666667 5.55228475,10 5,10 C4.44771525,10 4.11438192,9.66666667 4,9 L4,4 L9,4 C9.66666667,4 10,4.33333333 10,5 C10,5.66666667 9.66666667,6 9,6 L6,6 Z" fill="#000000" fillRule="nonzero" />
                                    </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                </span>
                                </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                                <div className="font-size-h4 text-dark-75 font-weight-bolder">$5.8M</div>
                                <div className="font-size-sm text-muted font-weight-bold mt-1">All Time Sales</div>
                            </div>
                            {/*end::Title*/}
                            </div>
                        </div>
                        {/*end::Item*/}
                        </div>
                    </div>
                    {/*end::Items*/}
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::Mixed Widget 16*/}
                </div>
                <div className="col-lg-8">
                {/*begin::Base Table Widget 2*/}
                <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">New Arrivals</span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>
                    </h3>
                    <div className="card-toolbar">
                        <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                        <li className="nav-item">
                            <a className="nav-link py-2 px-4" data-toggle="tab" href="#kt_tab_pane_2_1">Month</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link py-2 px-4" data-toggle="tab" href="#kt_tab_pane_2_2">Week</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link py-2 px-4 active" data-toggle="tab" href="#kt_tab_pane_2_3">Day</a>
                        </li>
                        </ul>
                    </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                    <div className="tab-content mt-5" id="myTabTables2">
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="kt_tab_pane_2_1" role="tabpanel" aria-labelledby="kt_tab_pane_2_1">
                        {/*begin::Table*/}
                        <div className="table-responsive">
                            <table className="table table-borderless table-vertical-center">
                            <thead>
                                <tr>
                                <th className="p-0 w-50px" />
                                <th className="p-0 min-w-150px" />
                                <th className="p-0 min-w-140px" />
                                <th className="p-0 min-w-120px" />
                                <th className="p-0 min-w-40px" />
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/006-plurk.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Top Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Successful Fellas</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">ReactJs, HTML</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">4600 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/014-kickstarter.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Bestseller Theme</Link>
                                    <span className="text-muted font-weight-bold d-block">Amazing Templates</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">ReactJS, Ruby</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">354 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/015-telegram.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Popular Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Most Successful</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">Python, MySQL</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">7200 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/003-puzzle.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">New Users</Link>
                                    <span className="text-muted font-weight-bold d-block">Awesome Users</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">Laravel, Metronic</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">890 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/005-bebo.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Active Customers</Link>
                                    <span className="text-muted font-weight-bold d-block">Best Customers</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">AngularJS, C#</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">6370 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        {/*end::Table*/}
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade" id="kt_tab_pane_2_2" role="tabpanel" aria-labelledby="kt_tab_pane_2_2">
                        {/*begin::Table*/}
                        <div className="table-responsive">
                            <table className="table table-borderless table-vertical-center">
                            <thead>
                                <tr>
                                <th className="p-0" style={{width: 50}} />
                                <th className="p-0" style={{minWidth: 150}} />
                                <th className="p-0" style={{minWidth: 140}} />
                                <th className="p-0" style={{minWidth: 120}} />
                                <th className="p-0" style={{minWidth: 40}} />
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/015-telegram.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Popular Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Most Successful</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">Python, MySQL</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">7200 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/003-puzzle.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">New Users</Link>
                                    <span className="text-muted font-weight-bold d-block">Awesome Users</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">Laravel, Metronic</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">890 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/005-bebo.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Active Customers</Link>
                                    <span className="text-muted font-weight-bold d-block">Best Customers</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">AngularJS, C#</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">6370 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/006-plurk.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Top Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Successful Fellas</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">ReactJs, HTML</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">4600 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/014-kickstarter.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Bestseller Theme</Link>
                                    <span className="text-muted font-weight-bold d-block">Amazing Templates</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">ReactJS, Ruby</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">354 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        {/*end::Table*/}
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div className="tab-pane fade show active" id="kt_tab_pane_2_3" role="tabpanel" aria-labelledby="kt_tab_pane_2_3">
                        {/*begin::Table*/}
                        <div className="table-responsive">
                            <table className="table table-borderless table-vertical-center">
                            <thead>
                                <tr>
                                <th className="p-0" style={{width: 50}} />
                                <th className="p-0" style={{minWidth: 150}} />
                                <th className="p-0" style={{minWidth: 140}} />
                                <th className="p-0" style={{minWidth: 120}} />
                                <th className="p-0" style={{minWidth: 40}} />
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/006-plurk.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Top Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Successful Fellas</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">ReactJs, HTML</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">4600 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "dist/assets/media/svg/misc/015-telegram.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Popular Authors</Link>
                                    <span className="text-muted font-weight-bold d-block">Most Successful</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">Python, MySQL</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">7200 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "dist/assets/media/svg/misc/003-puzzle.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">New Users</Link>
                                    <span className="text-muted font-weight-bold d-block">Awesome Users</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">Laravel, Metronic</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">890 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/005-bebo.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Active Customers</Link>
                                    <span className="text-muted font-weight-bold d-block">Best Customers</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">AngularJS, C#</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">6370 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                                <tr>
                                <td className="pl-0 py-5">
                                    <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                        <img alt="avatar" src={process.env.PUBLIC_URL+ "/dist/assets/media/svg/misc/014-kickstarter.svg"} className="h-50 align-self-center"/>
                                    </span>
                                    </div>
                                </td>
                                <td className="pl-0">
                                    <Link to="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Bestseller Theme</Link>
                                    <span className="text-muted font-weight-bold d-block">Amazing Templates</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">ReactJS, Ruby</span>
                                </td>
                                <td className="text-right">
                                    <span className="text-muted font-weight-bold">354 Users</span>
                                </td>
                                <td className="text-right pr-0">
                                    <Link to="#" className="btn btn-icon btn-light btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        {/*begin::Svg Icon | path:%PUBLIC_URL%/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x={11} y={5} width={2} height={14} rx={1} />
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                        </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                    </Link>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        {/*end::Table*/}
                        </div>
                        {/*end::Tap pane*/}
                    </div>
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::Base Table Widget 2*/}
                </div>
            </div>
            {/*end::Row*/}
            {/*end::Dashboard*/}
        </div>
    )
}

export default DashboardComponent;