import React from 'react';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import HeaderMoblileComponent from './HeaderMobileComponent';
import UserProfileComponent from './UserProfileComponent';

const LayoutComponent = (props) => {
    return(
        <div>
            <HeaderMoblileComponent/>
            <div className="d-flex flex-column flex-root">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <HeaderComponent/>
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            {/*begin::Entry*/}
                            <div className="d-flex flex-column-fluid">
                                {/*begin::Container*/}
                                {props.children}
                                {/*end::Container*/}
                            </div>
                            {/*end::Entry*/}
                        </div>
                        <FooterComponent/>
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            <UserProfileComponent/>
        </div>

    )
}

export default LayoutComponent;