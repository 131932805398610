const endpoint = {
    LOGIN: 'login',
    LOGOUT: 'logout',
    TOKEN: 'token',
    USER_CREATE_USER: 'users',
    USER_LIST_USER: 'master/listUser',
    USER_FIND_USER: 'users/find',
    USER_GET_USER: 'users/',
    USER_UPDATE_USER: 'users/',
    USER_DELETE_USER: 'users/',
    USER_LIST_MENU: 'users/menu/listMenu',
    USER_UPDATE_MENU: 'users/menu/listMenu',
    MENU_LIST_MENU: 'master/listMenu',
    CAS_UNLOCK_XML:'tools/unlockSendingXml',
    CAS_PUSH_MANIFEST_CAS:'tools/pushDataManifest',
    CAS_GET_XML:'tools/getXmlList',
    CAS_LOG_SENDING_XML:'tools/getLogSendingXml',
    CAS_GET_RESPON_BK:'tools/getResponBarangKiriman',
    CAS_GET_LIST_ITEM_SERVICE_NUMBER:'tools/item/',
    CAS_UPDATE_ITEM_BY_ID:'tools/item/',
    CAS_DELETE_ITEM_BY_ID:'tools/item/',
    CAS_GET_DATA_HEADER_SERVICE_NUMBER:'tools/shipmentData/',
    CAS_PUT_DATA_HEADER_SERVICE_NUMBER:'tools/shipmentData/',
    CAS_UPLOAD_FILE:'upload/formData',
    TICKET_CREATE_TICKET:'ticket/createTicket',
    TICKET_GET_LIST_TICKET:'ticket/getListTicket',
    TICKET_UPDATE_TICKET:'ticket/updateTicket',
    TICKET_POST_MESSAGE_TICKET:'ticket/postMessageTicket',
    TICKET_GET_LIST_MESSAGE_TICKET:'ticket/getListMessageTicket',
    UPLOAD_FILE_POST:'upload/post',
    UPLOAD_FILE_DELETE:'upload/delete',
    GTLN_RECALCULATE:'tools/GTLN_RECALCULATE',
    GTLN_DELETE_MANIFEST:'tools/GTLN_DELETE_MANIFEST',
    GTLN_UNLOCK_IMEI_SENDING:'tools/GTLN_UNLOCK_IMEI_SENDING',
    GTLN_DATA_SHIPPER:'tools/GTLN_DATA_SHIPPER',
    GTLN_UPDATE_DATA_SHIPPER:'tools/GTLN_UPDATE_DATA_SHIPPER',
}
export default endpoint;