import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import ENDPOINT from "../../../config/endpoint";
import { BASE_URI, PREFIX_URI } from "../../../config/config";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { useHeaders } from "../../context/HeadersProvider";
import { setInputColor } from "../../service/PageService";
import notification from "../../../config/notification";
import { converDate } from "../../../utils";

const UpdateHeaderComponent = () => {
  const USERDATA = localStorage.getItem("credential")
    ? JSON.parse(localStorage.getItem("credential")).userData
    : "";
  const [pageInitial, setPageInitial] = useState({
    isBlocking: false,
    isValid: false,
    formResult: false,
    modalShow: false,
    lockForm: false,
    formOpenBy: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [nomorSurat, setNomorSurat] = useState("");

  const { dataHeaders, getShipmentByServiceNumber, updateShipmentData } =
    useHeaders();

  const [cardCollap, setCardCollap] = useState({
    header: true,
    consignee: true,
    shipper: true,
    flag_status: true,
  });

  const [dataHeader, setDataHeader] = useState({
    id_manifest: "",
    service_number: "",
    invoice_date: "",
    waybill_number: "",
    waybill_date: "",
    total_weight: "",
    quantity_package: "",
    bc11: "",
    tgl_bc11: "",
    no_pos: "",
    no_sub_pos: "",
    no_sub_sub_pos: "",
    airline_name: "",
    flight_date: "",
    airline_code: "",
    flight_number: "",
    origin_country: "",
    destination_country: "",
  });

  const [dataConsignee, setDataConsignee] = useState({
    idCustomer: "",
    idConsignee: "",
    consigneeName: "",
    consigneeCompany: "",
    consigneePhone: "",
    consigneeMobile: "",
    consigneeCountry: "",
    consigneeCountryCode: "",
    consigneeCity: "",
    consigneeAddress1: "",
    consigneeAddress2: "",
    consigneeNpwp: "",
    consigneeApi: "",
    consigneeNib: "",
    consigneeNik: "",
    consigneeIdType: "",
    consigneeIdValue: "",
  });
  const [dataShipper, setDataShipper] = useState({
    shipperId: "",
    shipperName: "",
    shipperCompany: "",
    shipperPhone: "",
    shipperMobile: "",
    shipperCountry: "",
    shipperCountryCode: "",
    shipperCity: "",
    shipperAddress1: "",
    shipperAddress2: "",
  });

  const [flagStatus, setFlagStatus] = useState({
    idManifest: "",
    sendingXml: false,
    pecahPos: false,
    cleansing: false,
  });

  const [textServiceNumber, setTextServiceNumber] = useState(null);
  const inputTextServiceNumber = useRef();
  const labelHelpText = useRef();
  const inputNomorSurat = useRef();
  const labelInputNomorSurat = useRef();
  const inputFile = useRef();
  const lableInputFile = useRef();
  const lableFileName = useRef();

  const handleGetData = async () => {
    if (!pageInitial.isValid) {
      return;
    }
    const formData = {
      url:
        BASE_URI +
        PREFIX_URI +
        ENDPOINT.CAS_GET_DATA_HEADER_SERVICE_NUMBER +
        textServiceNumber,
      payload: JSON.stringify({ service_number: textServiceNumber }),
      method: "GET",
    };
    getShipmentByServiceNumber(formData);
  };

  // const converDate = (date) => {
  //     const dateString = new Date(date).toISOString().replace(/T/, ' ').replace(/\..+/, '');
  //     return dateString.substring(0,10)
  // }

  useEffect(() => {
    if (dataHeaders.length > 0) {
      setPageInitial({
        ...pageInitial,
        formResult: true,
        lockForm: dataHeaders[0].pecahpos === 1 ? true : false,
      });
      setDataHeader({
        ...dataHeader,
        id_manifest: dataHeaders[0].id_manifest,
        service_number: dataHeaders[0].service_number,
        invoice_date: dataHeaders[0].invoice_date,
        waybill_number: dataHeaders[0].waybill_number,
        waybill_date: converDate(dataHeaders[0].waybill_date),
        total_weight: dataHeaders[0].total_weight,
        quantity_package: dataHeaders[0].quantity_package,
        bc11: dataHeaders[0].bc11,
        tgl_bc11: dataHeaders[0].tgl_bc11,
        no_pos: dataHeaders[0].no_pos,
        no_sub_pos: dataHeaders[0].no_sub_pos,
        no_sub_sub_pos: dataHeaders[0].no_sub_sub_pos,
        airline_code: dataHeaders[0].airline_code,
        airline_name: dataHeaders[0].airline_name,
        flight_date: converDate(dataHeaders[0].flight_date),
        flight_number: dataHeaders[0].flight_number,
        origin_country: dataHeaders[0].origin_country,
        destination_country: dataHeaders[0].destination_country,
      });
      setDataConsignee({
        ...dataConsignee,
        idCustomer: dataHeaders[0].id_m_cust,
        idConsignee: dataHeaders[0].id_consignee,
        consigneeName: dataHeaders[0].consignee_name,
        consigneeCompany: dataHeaders[0].consignee_company,
        consigneePhone: dataHeaders[0].consignee_phone,
        consigneeMobile: dataHeaders[0].consignee_mobile,
        consigneeCountry: dataHeaders[0].consignee_country,
        consigneeCountryCode: dataHeaders[0].consignee_country_code,
        consigneeCity: dataHeaders[0].consignee_city,
        consigneeAddress1: dataHeaders[0].consignee_address_1,
        consigneeAddress2: dataHeaders[0].consignee_address_2,
        consigneeNpwp: dataHeaders[0].consignee_npwp,
        consigneeApi: dataHeaders[0].consignee_api,
        consigneeNib: dataHeaders[0].consignee_nib,
        consigneeNik: dataHeaders[0].consignee_nik,
        consigneeIdType: dataHeaders[0].consignee_id_type,
        consigneeIdValue: dataHeaders[0].consignee_id_value,
      });
      setDataShipper({
        ...dataShipper,
        shipperId: dataHeaders[0].id_shipper,
        shipperName: dataHeaders[0].shipper_name,
        shipperCompany: dataHeaders[0].shipper_company,
        shipperPhone: dataHeaders[0].shipper_phone,
        shipperMobile: dataHeaders[0].shipper_mobile,
        shipperCountry: dataHeaders[0].shipper_country,
        shipperCountryCode: dataHeaders[0].shipper_country_code,
        shipperCity: dataHeaders[0].shipper_city,
        shipperAddress1: dataHeaders[0].shipper_address_1,
        shipperAddress2: dataHeaders[0].shipper_address_2,
      });
      setFlagStatus({
        ...flagStatus,
        idManifest: dataHeaders[0].id_manifest,
        cleansing: dataHeaders[0].cleansing === 1 ? true : false,
        pecahPos: dataHeaders[0].pecahpos === 1 ? true : false,
        sendingXml: dataHeaders[0].sending_xml === 2 ? true : false,
      });
    } else {
      setPageInitial({ ...pageInitial, formResult: false });
      setCardCollap({
        ...cardCollap,
        header: true,
        consignee: true,
        shipper: true,
      });
    }
  }, [dataHeaders]);

  useEffect(() => {
    if (nomorSurat !== "") {
      setInputColor(inputNomorSurat, labelInputNomorSurat, "", "success");
    }
  }, [nomorSurat]);
  useEffect(() => {
    if (nomorSurat !== "") {
      setInputColor(inputFile, lableInputFile, "", "success");
    }
  }, [selectedFile]);

  const handleSubmitStatus = (e) => {
    e.preventDefault();
    const formData = {
      url:
        BASE_URI +
        PREFIX_URI +
        ENDPOINT.CAS_PUT_DATA_HEADER_SERVICE_NUMBER +
        flagStatus.idManifest +
        "?type=status",
      payload: JSON.stringify({
        cleansing: flagStatus.cleansing ? 1 : 0,
        pecahPos: flagStatus.pecahPos ? 1 : 0,
        sendingXml: flagStatus.sendingXml ? 2 : 0,
        data_before: dataHeaders,
        created_by: USERDATA.name,
        serviceNumber: textServiceNumber,
      }),
      method: "PUT",
    };
    updateShipmentData(formData);
  };

  const handleSubmitShipper = (e) => {
    e.preventDefault();
    dataShipper.serviceNumber = textServiceNumber;
    dataShipper.data_before = dataHeaders;
    if (pageInitial.lockForm) {
      setPageInitial({
        ...pageInitial,
        modalShow: true,
        formOpenBy: "shipper",
      });
    }
  };

  const handleSubmitConsignee = (e) => {
    e.preventDefault();
    dataConsignee.data_before = dataHeaders;
    dataConsignee.serviceNumber = textServiceNumber;
    if (pageInitial.lockForm) {
      setPageInitial({
        ...pageInitial,
        modalShow: true,
        formOpenBy: "consignee",
      });
    }
  };

  const handleSubmitHeader = (e) => {
    e.preventDefault();
    dataHeader.data_before = dataHeaders;
    dataHeader.created_by = USERDATA.name;
    dataHeader.serviceNumber = textServiceNumber;
    const formData = {
      url:
        BASE_URI +
        PREFIX_URI +
        ENDPOINT.CAS_PUT_DATA_HEADER_SERVICE_NUMBER +
        flagStatus.idManifest +
        "?type=header",
      payload: JSON.stringify(dataHeader),
      method: "PUT",
    };
    updateShipmentData(formData);
  };

  const handleSubmitFile = async (e) => {
    e.preventDefault();
    if (nomorSurat === "") {
      setInputColor(
        inputNomorSurat,
        labelInputNomorSurat,
        "Nomor Surat harap diisi!",
        "danger"
      );
      return;
    }
    if (selectedFile === null) {
      setInputColor(
        inputFile,
        lableInputFile,
        "Harap Lampirkan File!",
        "danger"
      );
      return;
    }

    const formData = new FormData();
    formData.append("fileReference", selectedFile, selectedFile.name);

    const uri = BASE_URI + PREFIX_URI + ENDPOINT.CAS_UPLOAD_FILE;
    const apiCall = await fetch(uri, { method: "POST", body: formData });
    const response = await apiCall.json();
    if (!response.success) {
      return notification.failed("Failed", "Upload File Failed!");
    }

    // const arrayType = ['consignee', 'shipper'];
    if (pageInitial.formOpenBy === "consignee") {
      dataConsignee.reference = nomorSurat;
      dataConsignee.reference_filename = response.data.fileName;
      dataConsignee.reference_filepath = response.data.filePath;
      dataConsignee.created_by = USERDATA.name;
      const formData = {
        url:
          BASE_URI +
          PREFIX_URI +
          ENDPOINT.CAS_PUT_DATA_HEADER_SERVICE_NUMBER +
          flagStatus.idManifest +
          "?type=consignee",
        payload: JSON.stringify(dataConsignee),
        method: "PUT",
      };
      updateShipmentData(formData);
    } else if (pageInitial.formOpenBy === "shipper") {
      dataShipper.reference = nomorSurat;
      dataShipper.reference_filename = response.data.fileName;
      dataShipper.reference_filepath = response.data.filePath;
      dataShipper.created_by = USERDATA.name;
      const formData = {
        url:
          BASE_URI +
          PREFIX_URI +
          ENDPOINT.CAS_PUT_DATA_HEADER_SERVICE_NUMBER +
          flagStatus.idManifest +
          "?type=shipper",
        payload: JSON.stringify(dataShipper),
        method: "PUT",
      };
      updateShipmentData(formData);
    }
    setPageInitial({ ...pageInitial, modalShow: false, formOpenBy: "" });
  };

  const handleChangeFile = (event) => {
    setSelectedFile(event.target.files[0]);
    lableFileName.current.innerHTML = event.target.files[0].name;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md">
          {/*begin::Card*/}
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <h3 className="card-title">Update Data Header</h3>
            </div>
            {/*begin::Form*/}
            <div className="card-body">
              <BlockUi
                tag="div"
                blocking={pageInitial.isBlocking}
                renderChildren={false}
              >
                <div className="form-group mb-8">
                  <div
                    className="alert alert-custom alert-default"
                    role="alert"
                  >
                    <div className="alert-icon">
                      <span className="svg-icon svg-icon-primary svg-icon-xl">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Tools/Compass.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
                              fill="#000000"
                              fillRule="nonzero"
                            />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                    </div>
                    <div className="alert-text">
                      Untuk mengupdate :<div>- Tgl Airwaybill</div>
                      <div>- Tgl Master Airwaybill</div>
                      <div>- No POS</div>
                      <div>- Tgl BC1.1</div>
                      <div>- dan lain nya</div>
                    </div>
                    {/* <div className="alert-text">Untuk mengupdate</div> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        ref={inputTextServiceNumber}
                        className="form-control"
                        required
                        placeholder="Service Number"
                        onKeyUp={(e) => {
                          setTextServiceNumber(e.target.value);
                          if (e.target.value.length < 11) {
                            setInputColor(
                              inputTextServiceNumber,
                              labelHelpText,
                              "Service Number tidak valid",
                              "danger"
                            );
                          } else {
                            setInputColor(
                              inputTextServiceNumber,
                              labelHelpText,
                              "Service Number valid",
                              "success"
                            );
                            setPageInitial({ ...pageInitial, isValid: true });
                          }
                        }}
                      />
                      <span
                        className="form-text text-muted"
                        ref={labelHelpText}
                      >
                        Harap isi dengan Service Number
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label> </label>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleGetData()}
                      >
                        Get Data
                      </button>
                    </div>
                  </div>
                </div>
              </BlockUi>
              {pageInitial.formResult ? (
                <>
                  {/* CARD HEADER */}
                  <div
                    className={`card card-custom ${
                      cardCollap.header ? "card-collapse" : ""
                    }`}
                    id="kt_card_3"
                    style={{
                      border: "1px solid #3699ff",
                      borderColor: "#3699ff",
                    }}
                  >
                    <div
                      className="card-header"
                      style={{ backgroundColor: "#3699ff" }}
                    >
                      <div className="card-title">
                        <h3 className="card-label" style={{ color: "white" }}>
                          Header
                        </h3>
                      </div>
                      <div className="card-toolbar">
                        <a
                          onClick={() =>
                            setCardCollap({
                              ...cardCollap,
                              header: !cardCollap.header,
                            })
                          }
                          className="btn btn-icon btn-circle btn-sm btn-light-primary mr-1"
                          data-card-tool="toggle"
                        >
                          <i className="ki ki-arrow-down icon-nm" />
                        </a>
                      </div>
                    </div>
                    <div
                      className="card-body"
                      style={
                        cardCollap.header
                          ? {
                              display: "none",
                              overflow: "hidden",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }
                          : {}
                      }
                    >
                      <form onSubmit={handleSubmitHeader}>
                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Hawb</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Service Number"
                                disabled={true}
                                value={dataHeader.service_number}
                                onChange={(e) => {}}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Tgl Hawb</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Tgl Service Number"
                                value={dataHeader.invoice_date}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    invoice_date: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Master Airwaybill</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Master Airwaybill"
                                value={dataHeader.waybill_number}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    waybill_number: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Tgl Master</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Tgl Master Airwaybill"
                                value={dataHeader.waybill_date}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    waybill_date: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Airlines</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Airlines Name"
                                value={dataHeader.airline_name}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    airline_name: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>Koli</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Airlines Name"
                                value={dataHeader.quantity_package}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    quantity_package: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>Kg</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Airlines Name"
                                value={dataHeader.total_weight}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    total_weight: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>BC1.1</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="No BC1.1"
                                value={dataHeader.bc11}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    bc11: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Tgl BC1.1</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Tgl BC1.1"
                                value={dataHeader.tgl_bc11}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    tgl_bc11: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>No POS</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nomor POS"
                                value={dataHeader.no_pos}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    no_pos: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>Subpos</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nomor Subpos"
                                value={dataHeader.no_sub_pos}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    no_sub_pos: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Flight Date</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Flight Date"
                                value={dataHeader.flight_date}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    flight_date: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>Airlines</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Airline Code"
                                value={dataHeader.airline_code}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    airline_code: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>Flight No</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Flight Number"
                                value={dataHeader.flight_number}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    flight_number: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>Origin</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Origin"
                                value={dataHeader.origin_country}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    origin_country: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>Destination</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Destination"
                                value={dataHeader.destination_country}
                                onChange={(e) =>
                                  setDataHeader({
                                    ...dataHeader,
                                    destination_country: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group float-right">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* END CARD HEADER */}
                  {/* CARD CONSIGNEE */}
                  <div
                    className={`card card-custom ${
                      cardCollap.consignee ? "card-collapse" : ""
                    } mt-5`}
                    id="kt_card_3"
                    style={{
                      border: "1px solid #3699ff",
                      borderColor: "#3699ff",
                    }}
                  >
                    <div
                      className="card-header"
                      style={{ backgroundColor: "#3699ff" }}
                    >
                      <div className="card-title">
                        <h3 className="card-label" style={{ color: "white" }}>
                          Consignee
                        </h3>
                      </div>
                      <div className="card-toolbar">
                        <a
                          onClick={() =>
                            setCardCollap({
                              ...cardCollap,
                              consignee: !cardCollap.consignee,
                            })
                          }
                          className="btn btn-icon btn-circle btn-sm btn-light-primary mr-1"
                          data-card-tool="toggle"
                        >
                          <i className="ki ki-arrow-down icon-nm" />
                        </a>
                      </div>
                    </div>
                    <div
                      className="card-body"
                      style={
                        cardCollap.consignee
                          ? {
                              display: "none",
                              overflow: "hidden",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }
                          : {}
                      }
                    >
                      <form onSubmit={handleSubmitConsignee}>
                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Id Customer</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Id Master Customer"
                                disabled={true}
                                value={dataConsignee.idCustomer}
                                onChange={(e) => {}}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Consignee Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Tgl Service Number"
                                value={dataConsignee.consigneeName}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeName: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Company</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Master Airwaybill"
                                value={dataConsignee.consigneeCompany}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeCompany: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Phone</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Consignee Phone"
                                value={dataConsignee.consigneePhone}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneePhone: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Mobile</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Consignee Mobile"
                                value={dataConsignee.consigneeMobile}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeMobile: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Country</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Consignee Country"
                                value={dataConsignee.consigneeCountry}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeCountry: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>Code</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Consignee Country Code"
                                value={dataConsignee.consigneeCountryCode}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeCountryCode: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>City</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nomor POS"
                                value={dataConsignee.consigneeCity}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consignee_city: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Consignee Address 1</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nomor Consignee Address 1"
                                value={dataConsignee.consigneeAddress1}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeAddress1: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Consignee Address 2</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Consignee Address 2"
                                value={dataConsignee.consigneeAddress2}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeAddress2: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>NPWP</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Consignee Npwp"
                                value={dataConsignee.consigneeNpwp}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeNpwp: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>API</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Consignee Api"
                                value={dataConsignee.consigneeApi}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeApi: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>NIK</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Consignee NIK"
                                value={dataConsignee.consigneeNik}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeNik: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>NIB</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Consignee NIB"
                                value={dataConsignee.consigneeNib}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeNib: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>ID Type</label>
                              <select
                                name=""
                                id=""
                                className="form-control"
                                value={dataConsignee.consigneeIdType}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeIdType: e.target.value,
                                  })
                                }
                              >
                                <option value="0">NPWP 12 Digit</option>
                                <option value="1">NPWP 10 Digit</option>
                                <option value="2">Passport</option>
                                <option value="3">KTP</option>
                                <option value="4">Lainnya</option>
                                <option value="5">NPWP 15 Digit</option>
                              </select>
                              {/* <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Consignee ID Type"
                                        value={dataConsignee.consigneeIdType}
                                        onChange={ e => setDataConsignee({...dataConsignee,consigneeIdType:e.target.value}) }
                                    /> */}
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>ID Value</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Consignee ID Value"
                                value={dataConsignee.consigneeIdValue}
                                onChange={(e) =>
                                  setDataConsignee({
                                    ...dataConsignee,
                                    consigneeIdValue: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group float-right">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* END CARD CONSIGNEE */}
                  {/* CARD SHIPPER */}
                  <div
                    className={`card card-custom ${
                      cardCollap.shipper ? "card-collapse" : ""
                    } mt-5`}
                    id="kt_card_3"
                    style={{
                      border: "1px solid #3699ff",
                      borderColor: "#3699ff",
                    }}
                  >
                    <div
                      className="card-header"
                      style={{ backgroundColor: "#3699ff" }}
                    >
                      <div className="card-title">
                        <h3 className="card-label" style={{ color: "white" }}>
                          Shipper
                        </h3>
                      </div>
                      <div className="card-toolbar">
                        <a
                          onClick={() =>
                            setCardCollap({
                              ...cardCollap,
                              shipper: !cardCollap.shipper,
                            })
                          }
                          className="btn btn-icon btn-circle btn-sm btn-light-primary mr-1"
                          data-card-tool="toggle"
                        >
                          <i className="ki ki-arrow-down icon-nm" />
                        </a>
                      </div>
                    </div>
                    <div
                      className="card-body"
                      style={
                        cardCollap.shipper
                          ? {
                              display: "none",
                              overflow: "hidden",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }
                          : {}
                      }
                    >
                      <form onSubmit={handleSubmitShipper}>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Shipper Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Shipper Name"
                                value={dataShipper.shipperName}
                                onChange={(e) =>
                                  setDataShipper({
                                    ...dataShipper,
                                    shipperName: e.target.value,
                                  })
                                }
                              />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="shipper id"
                                value={dataShipper.shipperId}
                                hidden={true}
                                onChange={(e) =>
                                  setDataShipper({
                                    ...dataShipper,
                                    shipperId: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Company</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Shipper Company"
                                value={dataShipper.shipperCompany}
                                onChange={(e) =>
                                  setDataShipper({
                                    ...dataShipper,
                                    shipperCompany: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Phone</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Shipper Phone"
                                value={dataShipper.shipperPhone}
                                onChange={(e) =>
                                  setDataShipper({
                                    ...dataShipper,
                                    shipperPhone: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Mobile</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Shipper Mobile"
                                value={dataShipper.shipperMobile}
                                onChange={(e) =>
                                  setDataShipper({
                                    ...dataShipper,
                                    shipperMobile: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>Country</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Shipper Country"
                                value={dataShipper.shipperCountry}
                                onChange={(e) =>
                                  setDataShipper({
                                    ...dataShipper,
                                    shipperCountry: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>Code</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Shipper Country Code"
                                value={dataShipper.shipperCountryCode}
                                onChange={(e) =>
                                  setDataShipper({
                                    ...dataShipper,
                                    shipperCountryCode: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group">
                              <label>City</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nomor POS"
                                value={dataShipper.shipperCity}
                                onChange={(e) =>
                                  setDataShipper({
                                    ...dataShipper,
                                    shipperCity: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Shipper Address 1</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nomor Shipper Address 1"
                                value={dataShipper.shipperAddress1}
                                onChange={(e) =>
                                  setDataShipper({
                                    ...dataShipper,
                                    shipperAddress1: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Shipper Address 2</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Shipper Address 2"
                                value={dataShipper.shipperAddress2}
                                onChange={(e) =>
                                  setDataShipper({
                                    ...dataShipper,
                                    shipperAddress2: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group float-right">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* END CARD SHIPPER */}
                  {/* CARD STATUS */}
                  <div
                    className={`card card-custom ${
                      cardCollap.flag_status ? "card-collapse" : ""
                    } mt-5`}
                    id="kt_card_3"
                    style={{
                      border: "1px solid #3699ff",
                      borderColor: "#3699ff",
                    }}
                  >
                    <div
                      className="card-header"
                      style={{ backgroundColor: "#3699ff" }}
                    >
                      <div className="card-title">
                        <h3 className="card-label" style={{ color: "white" }}>
                          Status
                        </h3>
                      </div>
                      <div className="card-toolbar">
                        <a
                          onClick={() =>
                            setCardCollap({
                              ...cardCollap,
                              flag_status: !cardCollap.flag_status,
                            })
                          }
                          className="btn btn-icon btn-circle btn-sm btn-light-primary mr-1"
                          data-card-tool="toggle"
                        >
                          <i className="ki ki-arrow-down icon-nm" />
                        </a>
                      </div>
                    </div>
                    <div
                      className="card-body"
                      style={
                        cardCollap.flag_status
                          ? {
                              display: "none",
                              overflow: "hidden",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }
                          : {}
                      }
                    >
                      <form className="form" onSubmit={handleSubmitStatus}>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">
                            Cleansing
                          </label>
                          <div className="col-3">
                            <span className="switch switch-outline switch-icon switch-primary">
                              <label>
                                <input
                                  type="checkbox"
                                  defaultChecked={flagStatus.cleansing}
                                  name="select"
                                  onChange={(e) => {
                                    setFlagStatus({
                                      ...flagStatus,
                                      cleansing: !flagStatus.cleansing,
                                    });
                                  }}
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">
                            Pecah POS
                          </label>
                          <div className="col-3">
                            <span className="switch switch-outline switch-icon switch-primary">
                              <label>
                                <input
                                  type="checkbox"
                                  defaultChecked={flagStatus.pecahPos}
                                  name="select"
                                  onChange={(e) => {
                                    setFlagStatus({
                                      ...flagStatus,
                                      pecahPos: !flagStatus.pecahPos,
                                    });
                                  }}
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-3 col-form-label">
                            Sending XML
                          </label>
                          <div className="col-3">
                            <span className="switch switch-outline switch-icon switch-primary">
                              <label>
                                <input
                                  type="checkbox"
                                  defaultChecked={flagStatus.sendingXml}
                                  name="select"
                                  onChange={(e) => {
                                    setFlagStatus({
                                      ...flagStatus,
                                      sendingXml: !flagStatus.sendingXml,
                                    });
                                  }}
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group float-right">
                              <button className="btn btn-primary" type="submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* END CARD STATUS */}
                </>
              ) : null}
            </div>
            {/*end::Form*/}
          </div>
          {/*end::Card*/}
        </div>
      </div>
      <Modal
        centered
        show={pageInitial.modalShow}
        onHide={() =>
          setPageInitial({ ...pageInitial, modalShow: false, formOpenBy: "" })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>Lampirkan Referensi</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitFile}>
          <Modal.Body>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nomor Surat</Form.Label>
              <Form.Control
                ref={inputNomorSurat}
                onChange={(e) => setNomorSurat(e.target.value)}
                type="text"
                placeholder="Masukan Nomor Surat"
              />
              <Form.Text ref={labelInputNomorSurat} className="text-muted">
                Tanpa Nomor surat tidak bisa mengubah apapun tentang consignee
                dan shipper
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <div className="form-group">
                <label>File Browser</label>
                <div />
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="customFile"
                    ref={inputFile}
                    onChange={handleChangeFile}
                    accept="application/pdf, .pdf"
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="customFile"
                    ref={lableFileName}
                  >
                    Choose file
                  </label>
                  <span className="form-text text-muted" ref={lableInputFile}>
                    Lampirkan File pdf
                  </span>
                </div>
              </div>

              {/* <Form.File id="exampleFormControlFile1" onChange={handleChangeFile} label="Example file input" /> */}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() =>
                setPageInitial({ ...pageInitial, modalShow: false })
              }
            >
              Close
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdateHeaderComponent;
