import React, { useRef, useState, useEffect } from 'react';
import ENDPOINT from '../../../config/endpoint';
import {BASE_URI,PREFIX_URI} from '../../../config/config';
import Notification from '../../../config/notification';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

const DataShipperComponent = () => {
    const [textServiceNumber,setTextServiceNumber] = useState('');
    const [id_shipper,Setid_shipper] = useState('');
    const [shipper_name,Setshipper_name] = useState('');
    const [shipper_company,Setshipper_company] = useState('');
    const [shipper_country,Setshipper_country] = useState('');
    const [shipper_country_code,Setshipper_country_code] = useState('');
    const [shipper_address_1,Setshipper_address_1] = useState('');
    const [shipper_address_2,Setshipper_address_2] = useState('');
    
    const initialState = {
        shipper_name: '',
        shipper_company: '',
        shipper_country: '',
        shipper_country_code: '',
        shipper_address_1:'',
        shipper_address_2:''
    }

    const [data, setData] = useState(initialState)
    const [isBlocking,setIsBlocking] = useState(false);
    const [isValid,setIsValid] = useState(false);
    const inputTextServiceNumber = useRef();
    const labelHelpText = useRef();

    
    useEffect(async () => {
        
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({'service_number':textServiceNumber}),
                redirect: 'follow'
            };

            const url = BASE_URI+PREFIX_URI+ENDPOINT.GTLN_DATA_SHIPPER;
            let loginApiCall = await fetch( url, requestOptions);
            let responses = await loginApiCall.json();
            console.log('responses',responses);
            
            setIsBlocking(false)
            setIsValid(false);

            if(!loginApiCall.ok){
                
                if(responses.message.errors){
                    const errorRaw = responses.message.errors;
                    const label = Object.keys(errorRaw);
                    let errorString = '';
                    for(var i=0;i<label.length;i++){
                    errorString += label[i] +' : '+ errorRaw[label[i]].join() + '\n';
                    }
                    
                    Notification.failed('Failed',errorString,'top-right');
                    return;
                }
                setData(initialState)
                Notification.failed('Failed',responses.message,'top-right');
            }else{
                if(textServiceNumber){
                    setData(responses.data[0])
                    // Setshipper_name(responses.data[0].shipper_name)
                    // Notification.success('Success',responses.message,'top-right');
                    
                }else{
                    setData(initialState)
                    // Notification.success('Loading','Silahkan isi Service Number','top-right');
                }
                
                // setTextServiceNumber(null);
            }
    },[textServiceNumber])

    const handleInputChange = (e) => {
        e.preventDefault()
        setData({
            id_shipper: data.id_shipper, 
            shipper_name: shipper_name.value, 
            shipper_company: shipper_company.value, 
            shipper_country: shipper_country.value,
            shipper_country_code: shipper_country_code.value,
            shipper_address_1: shipper_address_1.value,
            shipper_address_2: shipper_address_2.value})
        
    }

    const handleSubmit = async(e) =>  {
        e.preventDefault()
        const {shipper_name,
            shipper_company,
            shipper_country, 
            shipper_country_code,
            shipper_address_1,
            shipper_address_2 } = e.target.elements

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id_shipper: data.id_shipper,
                    shipper_name: shipper_name.value,
                    shipper_company: shipper_company.value, 
                    shipper_country: shipper_country.value,
                    shipper_country_code: shipper_country_code.value,
                    shipper_address_1: shipper_address_1.value,
                    shipper_address_2: shipper_address_2.value }),
                credentials: "same-origin"
            };
            
            console.log('requestOption',requestOptions);
            const url = BASE_URI+PREFIX_URI+ENDPOINT.GTLN_UPDATE_DATA_SHIPPER;
            let loginApiCall = await fetch( url, requestOptions);
            let responses = await loginApiCall.json();
            console.log('responses',responses);
            setIsBlocking(false)
            setIsValid(false);
            
        if(!loginApiCall.ok){
            Notification.failed('Failed',responses.message,'top-right');
        }else{
            Notification.success('Success',responses.message,'top-right');
            // setData(initialState)
        }
    }

    return(
        <div className="container">
            <div className="row">
                <div className="col-md">
                    {/* <BlockUi tag="div" blocking={isBlocking} renderChildren={false}> */}
                        {/*begin::Card*/}
                        <div className="card card-custom gutter-b example example-compact">
                            <div className="card-header">
                                <h3 className="card-title">Update Data Shipper</h3>
                            </div>
                            {/*begin::Form*/}
                            <form onSubmit={handleSubmit}>
                            
                                <div className="card-body">
                                    <BlockUi tag="div" blocking={isBlocking} renderChildren={false}>
                                        <div className="form-group mb-8">
                                            <div className="alert alert-custom alert-default" role="alert">
                                                <div className="alert-icon">
                                                    <span className="svg-icon svg-icon-primary svg-icon-xl">
                                                        {/*begin::Svg Icon | path:assets/media/svg/icons/Tools/Compass.svg*/}
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                <rect x={0} y={0} width={24} height={24} />
                                                                <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3" />
                                                                <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero" />
                                                            </g>
                                                        </svg>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </div>
                                                <div className="alert-text">Untuk melakukan perbaikan data shipper berdasarkan HAWB</div>
                                            </div>
                                        </div>
                                    
                                        <div className="form-group">
                                            <label>Service Number
                                                <span className="text-danger"> * </span>
                                            </label>
                                            <input 
                                                type="text" 
                                                ref={inputTextServiceNumber} 
                                                className="form-control" 
                                                id='TextServiceNumber'
                                                placeholder="Service Number" 
                                                onChange= { (e) => setTextServiceNumber(e.target.value) } 
                                                />
                                            <div className="form-group">
                                                <label>Shipper Name
                                                    <span className="text-danger"> * </span>
                                                </label>
                                                <input  type="text" 
                                                    className="form-control" 
                                                    id="shipper_name"
                                                    placeholder="Shipper Name"
                                                    value= { (data)?data.shipper_name:''}
                                                    onChange={handleInputChange}
                                                />
                                                <label>Shipper Company
                                                    <span className="text-danger"> * </span>
                                                </label>
                                                <input type="text" 
                                                    className="form-control"  
                                                    id="shipper_company"
                                                    placeholder="Shipper Company"
                                                    value= { (data)?data.shipper_company:''}
                                                    onChange={handleInputChange}
                                                />
                                                <label>Shipper Country
                                                    <span className="text-danger"> * </span>
                                                </label>
                                                <input type="text" 
                                                    className="form-control"  
                                                    id="shipper_country"
                                                    placeholder="Shipper Country"  
                                                    value= { (data)?data.shipper_country:''}
                                                    onChange={handleInputChange}
                                                />
                                                <label>Shipper Country Code
                                                    <span className="text-danger"> * </span>
                                                </label>
                                                <input type="text" 
                                                    className="form-control"  
                                                    id="shipper_country_code"
                                                    placeholder="Shipper Country Code"
                                                    value= { (data)?data.shipper_country_code:''}
                                                    onChange={handleInputChange}
                                                />
                                                <label>Shipper Address 1
                                                    <span className="text-danger"> * </span>
                                                </label>
                                                <input type="text" 
                                                    className="form-control"  
                                                    id="shipper_address_1"
                                                    placeholder="Shipper Address 1"
                                                    value= { (data)?data.shipper_address_1:''}
                                                    onChange={handleInputChange}
                                                />
                                                <label>Shipper Address 2
                                                    {/* <span className="text-danger"> * </span> */}
                                                </label>
                                                <input type="text" 
                                                    className="form-control"  
                                                    id="shipper_address_2"
                                                    placeholder="Shipper Address 2"
                                                    value= { (data)?data.shipper_address_2:''}
                                                    onChange={handleInputChange}
                                                />
                                                
                                            </div>
                                            <span className="form-text text-muted" ref={labelHelpText}>Masukan HAWB tanpa spasi</span>
                                            <hr/>
                                            
                                        </div>
                                    </BlockUi>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary mr-2">Submit</button>
                                </div>
                            </form>
                            {/*end::Form*/}
                        </div>
                        {/*end::Card*/}
                    {/* </BlockUi> */}
                </div>
            </div>
        </div>
    )
}

export default DataShipperComponent;