import React from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import MainRouter from './Components/router/MainRouter';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/ConfigureStore';
import { HeadersProvider } from './Components/context/HeadersProvider';
import { ItemsProvider } from './Components/context/ItemsProvider';
import { UsersProvider } from './Components/context/UsersProvider';


const store = ConfigureStore();

const App = () => {
  return (
    <Provider store={store}>
      <HeadersProvider>
        <ItemsProvider>
          <UsersProvider>
            <div className="App">
              <ReactNotification />
              <MainRouter/>
            </div>
          </UsersProvider>
        </ItemsProvider>
      </HeadersProvider>
    </Provider>
  );
}

export default App;
