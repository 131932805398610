import React from 'react';
import { Link } from 'react-router-dom';
const FooterComponent = () => {
    return(
        <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
            {/*begin::Container*/}
            <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
                {/*begin::Copyright*/}
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted font-weight-bold mr-2">2020©</span>
                    <Link to="http://keenthemes.com/metronic" target={"_blank"} className="text-dark-75 text-hover-primary">Keenthemes</Link>
                </div>
                {/*end::Copyright*/}
                {/*begin::Nav*/}
                <div className="nav nav-dark order-1 order-md-2">
                    {/* <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link pr-3 pl-0">About</a> */}
                    <Link to="http://keenthemes.com/metronic" target={"_blank"} className="nav-link pr-3 pl-0">About</Link>
                    <Link to="http://keenthemes.com/metronic" target={"_blank"} className="nav-link px-3">Team</Link>
                    <Link to="http://keenthemes.com/metronic" target={"_blank"} className="nav-link pl-3 pr-0">Contact</Link>
                </div>
                {/*end::Nav*/}
            </div>
            {/*end::Container*/}
        </div>

    )
}

export default FooterComponent;